import React from "react";
import {IProjectDetail} from "../Actions/Interfaces/IProject";
import RBS, {RetterCloudObject} from "@retter/sdk";
import {IUserStoreData, IUserStoreDataItems} from "../Actions/Stores/UserStore";

export interface IUserProviderContext {
    rootRbsSdk?: RBS
    detail?: IUserStoreDataItems
    instance?: RetterCloudObject
    isRootProjectMember?: boolean
}

export let UserProviderContext: React.Context<IUserProviderContext> = React.createContext<IUserProviderContext>({})
