import React, {Component} from "react";
import {Button, Form, Input, List, Modal, notification, Select, Skeleton, Spin, Tabs} from "antd";
import {RootProjectClassMethods} from "../Api/APIService";
import {RoleItem} from "../Interfaces/IRoles";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectContext} from "../Contexts/RootProjectContext";
import {userStore} from "../Actions/Stores/UserStore";
import {Unsubscribe} from "redux";
import {UserProviderContext} from "../Contexts/UserProviderContext";

const {Option} = Select;

interface Props {
    inviteModelClosed: () => void
    userInstance?: RetterCloudObject
    visible: boolean
}

interface InviteItem {
    status: string,
    invitedAt: number
    confirmedAt?: number
    email: string
}

interface State {
    loading: boolean
    visible: boolean
    developerRoles: RoleItem[]
    selectedRole: string
    unsub?: Unsubscribe
    invites?: InviteItem[]
}

export class InviteUserModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            developerRoles: [],
            selectedRole: ''
        }
        this.inviteUser = this.inviteUser.bind(this)
        this.selectRolesChange = this.selectRolesChange.bind(this)
        this.deleteInvitation = this.deleteInvitation.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    componentDidMount() {
        this.setState({
            loading: true
        })
        const state = userStore.getState()
        if (!state.data.invites) state.data.invites = {}
        this.setState({
            invites: Object.keys(state.data.invites).map(email => {
                return {
                    email,
                    status: state.data.invites![email].status,
                    invitedAt: state.data.invites![email].invitedAt
                }
            })
        })
        this.setState({
            unsub: userStore.subscribe(() => {
                const state = userStore.getState()
                this.setState({
                    invites: state.data.invites ? Object.keys(state.data.invites).map(email => {
                        return {
                            email,
                            status: state.data.invites![email].status,
                            invitedAt: state.data.invites![email].invitedAt,
                            confirmedAt: state.data.invites![email].confirmedAt
                        }
                    }) : []
                })
            })
        })
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        if (this.state.unsub) this.state.unsub()
    }

    async inviteUser(email: string) {
        this.setState({loading: true});
        try {
            if (!this.props.userInstance) throw new Error('user instance not found')
            await this.props.userInstance.call<any>({
                method: RootProjectClassMethods.inviteUser,
                body: {
                    email
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message
                })
            }
        }
        this.setState({loading: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
        this.props.inviteModelClosed()
    };

    onFinish = async (values: any) => {
        const {email} = values
        await this.inviteUser(email)

    };

    onFinishFailed = (errorInfo: any) => {

    };

    selectRolesChange(value: string, option: any) {
        this.setState({
            selectedRole: value
        })
    }

    async deleteInvitation(email: string) {
        this.setState({loading: true})
        try {
            await this.props.userInstance?.call({
                method: RootProjectClassMethods.deleteInvite,
                body: {
                    email
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message
                })
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {loading} = this.state;

        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        ctx ? <>
                            <UserProviderContext.Consumer>
                                {(uCtx) => (
                                    <Modal
                                        visible={this.props.visible}
                                        title="Invite"
                                        onCancel={this.handleCancel}
                                        footer={[
                                            <Button key="cancel" type={"link"} onClick={this.handleCancel}
                                                    loading={loading}>
                                                Cancel
                                            </Button>
                                        ]}
                                    >
                                        <Spin spinning={loading}>
                                            <Tabs defaultActiveKey="1">
                                                <Tabs.TabPane
                                                    tab={`Invite`}
                                                    key="invite">
                                                    <Form
                                                        layout={'vertical'}
                                                        id={'invite_user'}
                                                        name="basic"
                                                        initialValues={{remember: true}}
                                                        onFinish={this.onFinish}
                                                        onFinishFailed={this.onFinishFailed}
                                                    >
                                                        <Form.Item
                                                            label="Email"
                                                            name="email"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please input email address'
                                                            }]}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Button type="primary" form={"invite_user"} htmlType="submit">
                                                            Invite
                                                        </Button>
                                                    </Form>
                                                </Tabs.TabPane>
                                                <Tabs.TabPane
                                                    tab={`Invitations ${uCtx.detail?.invites ? Object.keys(uCtx.detail?.invites).length : 0}/${uCtx.detail?.inviteLimit ? uCtx.detail?.inviteLimit : 0}`}
                                                    key="invite_list">
                                                    <List
                                                        bordered
                                                        dataSource={this.state.invites}
                                                        renderItem={item => (
                                                            <List.Item
                                                                actions={item.status !== 'CONFIRMED' ? [<Button danger
                                                                                                                onClick={async () => {
                                                                                                                    await this.deleteInvitation(item.email)
                                                                                                                }}
                                                                                                                loading={this.state.loading}
                                                                                                                type={'link'}>Delete</Button>] : []}>
                                                                <Skeleton loading={false} title={false} active>
                                                                    <List.Item.Meta
                                                                        title={item.email}
                                                                        description={
                                                                            `${(new Date(item.invitedAt)).toLocaleString()}${
                                                                                item.confirmedAt ? ' - ' +
                                                                                    (new Date(item.confirmedAt!)).toLocaleString() : ''}`
                                                                        }
                                                                    />
                                                                    <div>{item.status}</div>
                                                                </Skeleton>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Tabs.TabPane>
                                            </Tabs>

                                        </Spin>
                                    </Modal>
                                )}
                            </UserProviderContext.Consumer>
                        </> : null
                    )}

                </RootProjectContext.Consumer>

            </>
        );
    }
}
