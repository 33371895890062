import React, {Component} from "react";
import CustomSpinner from "../Components/CustomSpinner";
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Skeleton,
    Space,
    Switch,
    Typography
} from "antd";
import {Column, ILogsTableDetail} from "../Interfaces/ILogsTableDetail";
import {LogsTable} from "../Components/LogsTable";
import {MinusCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import queryString from 'query-string'
import _ from "lodash";
import {IProjectContext, RootProjectContext} from "../Contexts/RootProjectContext";
import {RootProjectClassMethods} from "../Api/APIService";

const {Text} = Typography;
const {Option} = Select;

interface Props {
    projectId: string
}


type QueryLogItemOperator = '=' | '!=' | '>=' | '<=' | 'IN' | 'BEGINS_WITH' | 'ENDS_WITH' | 'CONTAIN'

export interface IQueryLogFormDataItem {
    key: string,
    operator: QueryLogItemOperator,
    value: string | boolean | number | undefined
    type: string
}

interface State {
    loading: boolean
    tableItems: any[]
    tableDetail?: ILogsTableDetail
    filterColumns: Column[]
    filters: Column[]
    queryLogFormData: IQueryLogFormDataItem[]
    autoSearch: boolean
    queryCurrentTime: boolean
}

class LogsLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            tableItems: [],
            filterColumns: [],
            filters: [],
            queryLogFormData: [],
            autoSearch: true,
            queryCurrentTime: true
        }
        this.query = this.query.bind(this)
        this.addFilterSelectChanged = this.addFilterSelectChanged.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.filterFormOnFinish = this.filterFormOnFinish.bind(this)
        this.prepareFilters = this.prepareFilters.bind(this)
        this.updateFormFieldValue = this.updateFormFieldValue.bind(this)
        this.updateQueryLogFormData = this.updateQueryLogFormData.bind(this)
        this.operatorChangeHandler = this.operatorChangeHandler.bind(this)
        this.addFilterSelectChanged = this.addFilterSelectChanged.bind(this)
        this.filterFormOnFinish = this.filterFormOnFinish.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevState.queryLogFormData !== this.state.queryLogFormData) {
            window.history.pushState({}, '',
                window.location.pathname + '?' + queryString.stringify(
                    {
                        'queryCurrentTime': this.state.queryCurrentTime,
                        'autoSearch': this.state.autoSearch,
                        'query': JSON.stringify(this.state.queryLogFormData)
                    }, {sort: false}))
        }
    }

    async prepareFilters() {
        const filteredColumns: Column[] = []
        let formData: IQueryLogFormDataItem[] = []
        let autoSearch = this.state.autoSearch
        let queryCurrentTime = this.state.queryCurrentTime
        const currentTimeFiltersData: IQueryLogFormDataItem[] = [
            {
                key: 'year',
                operator: '=',
                value: moment().utc().format('YYYY'),
                type: 'string'
            },
            {
                key: 'month',
                operator: '=',
                value: moment().utc().format('MM'),
                type: 'string'
            },
            {
                key: 'day',
                operator: '=',
                value: moment().utc().format('DD'),
                type: 'string'
            },
            {
                key: 'hour',
                operator: '=',
                value: moment().utc().format('HH'),
                type: 'string'
            }
        ]
        if ((!window.location.search || window.location.search === '') && this.state.queryCurrentTime) {
            formData = currentTimeFiltersData
        } else {
            const queryStings: any = queryString.parse(window.location.search, {arrayFormat: 'bracket', sort: false})
            queryCurrentTime = queryStings.queryCurrentTime === 'true'
            if (queryCurrentTime && (!queryStings.query || queryStings.query === '' || queryStings.query === '[]')) {
                formData = [...formData, ...currentTimeFiltersData]
            }
            if (queryStings.query) {
                try {
                    let filters: IQueryLogFormDataItem[] = JSON.parse(queryStings.query)
                    if (queryCurrentTime) {
                        currentTimeFiltersData.forEach(f => {
                            formData.push(f)
                        })
                    }
                    formData = [...formData, ...filters]
                    formData = _.uniqBy(formData, 'key')
                } catch (e) {

                }
            }
            autoSearch = queryStings.autoSearch === 'true'
        }
        formData.forEach((d, i) => {
            const column = this.state.filterColumns.find(c => c.Name === d.key)
            if (column) {
                formData[i].type = column.Type!
                filteredColumns.push(column)
                switch (column.Type) {
                    case 'number':
                        formData[i].value = parseInt(d.value as any)
                        break
                    case 'boolean':
                        formData[i].value = d.value === 'true'
                        break
                }
            }
        })

        await this.setState({
            filters: filteredColumns,
            queryLogFormData: formData,
            autoSearch,
            queryCurrentTime
        })

    }

    async componentDidMount() {
        this.ctx = this.context
        if (!this.ctx) throw new Error('ctx not found')

        const response = await this.ctx.instance?.call<any>({
            method: RootProjectClassMethods.getLogsTableDetails,
        })
        if (!response || response.status >= 400) {
            throw new Error('getLogsTableDetails response error')
        }
        if (response) {
            this.setState({
                tableDetail: response.data,
                filterColumns: [...response.data.columns, ...response.data.partitionKeys].filter(c => !["req", "res", "projectid"].includes(c.Name))
            })
        }
        await this.prepareFilters()
        if (this.state.autoSearch) {
            await this.query()
        }
        await this.setState({loading: false})
    }

    async query() {
        if (!this.ctx) throw new Error('ctx not found')
        this.setState({loading: true})


        const response = await this.ctx.instance?.call<any>({
            method: RootProjectClassMethods.getLogs,
            body: this.state.queryLogFormData
        })
        if (response) {
            const tableItems = []
            for (let i = 0; i < response.data.length; i++) {
                tableItems.push({...response.data[i], ...{key: i.toString()}})
            }
            this.setState({
                tableItems
            })
        }

        this.setState({loading: false})
    }

    async filterFormOnFinish() {
        await this.query()
    }

    async addFilterSelectChanged(value: string[], option: any) {

        const newFilters = option.map((o: any) => {
            const fieldData = this.state.queryLogFormData.find(d => d.key === o.data.Name)
            if (!fieldData) this.state.queryLogFormData.push({
                key: o.data.Name,
                operator: '=',
                value: undefined,
                type: o.data.Type || 'string'
            })
            return o.data

        })
        await this.setState({
            filters: newFilters,
            queryLogFormData: this.state.queryLogFormData
        })
    }

    async removeFilter(name: string) {
        if(["year", "month"].includes(name)) return false
        const newFilters = this.state.filters.filter((o: any) => {
            return o.Name !== name
        })
        await this.setState({
            filters: newFilters,
            queryLogFormData: this.state.queryLogFormData.filter(d => d.key !== name)
        })
    }

    async updateFormFieldValue(key: string, value: string | undefined) {
        await this.updateQueryLogFormData(key, {value})
    }

    getInitialFormData(key: string): { key: string, operator: QueryLogItemOperator, value: string | undefined | number | boolean } {
        const field = this.state.queryLogFormData.find(d => d.key === key)
        if (field) return field
        return {
            key: '',
            operator: '=',
            value: undefined
        }
    }

    async updateQueryLogFormData(key: string, props: { value?: any, operator?: QueryLogItemOperator }) {
        const copy: IQueryLogFormDataItem[] = JSON.parse(JSON.stringify(this.state.queryLogFormData))
        let index = -1
        copy.forEach((d, i) => {
            if (d.key === key) index = i
        })
        if (props.value) copy[index].value = props.value
        if (props.operator) copy[index].operator = props.operator
        await this.setState({
            queryLogFormData: copy
        })

    }

    async operatorChangeHandler(key: string, operator: QueryLogItemOperator) {
        await this.updateQueryLogFormData(key, {operator})
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading} skeleton={false}>
                    <Form name="logs_query" onFinish={this.filterFormOnFinish}
                          layout="vertical" id={'logs_query_form'} key={'logs_query_form'}>
                        <Form.Item>
                            <Row justify="space-between" gutter={[18, 6]}>
                                <Col span={18}>
                                    <Select
                                        onChange={this.addFilterSelectChanged}
                                        mode="multiple"
                                        value={this.state.filters.map(c => {
                                            return c.Name
                                        })}
                                        allowClear
                                        placeholder="Add Filter"
                                    >
                                        {
                                            this.state.tableDetail ? this.state.filterColumns.map(c =>
                                                <Option value={c.Name} data={c}>{c.Name}</Option>
                                            ) : null
                                        }
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" htmlType={"submit"} loading={this.state.loading}
                                            block>
                                        Get
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                        {
                            this.state.filters.map(f => {
                                return <Space key={f.Name} style={{display: 'flex', marginBottom: 8}}
                                              align="baseline">
                                    <Form.Item
                                        key={[f.Name, 'key'].join('#')}
                                        initialValue={f.Name}
                                        style={{width: '10vw'}}
                                        fieldKey={[f.Name, 'key']}
                                        name={[f.Name, 'key']}
                                        rules={[{required: true, message: 'Missing key'}]}
                                    >
                                        <Text strong key={f.Name}>{f.Name}</Text>
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={this.getInitialFormData(f.Name).operator}
                                        fieldKey={[f.Name, 'operator']}
                                        key={[f.Name, 'operator'].join('#')}
                                        style={{width: 150}}
                                        name={[f.Name, 'operator']}
                                        rules={[{required: true, message: 'Missing operator'}]}
                                    >
                                        <Select
                                            key={f.Name + '#' + 'select'}
                                            placeholder="Select operator"
                                            onChange={async (vt, o: any) => {
                                                await this.operatorChangeHandler(f.Name, o.value)
                                            }}
                                        >
                                            <Option value={"="}>EQ</Option>
                                            <Option value={"!="}>NEQ</Option>
                                            <Option value={">="}>GTE</Option>
                                            <Option value={"<="}>LTE</Option>
                                            <Option value={"IN"}>IN</Option>
                                            <Option value={"BEGINS_WITH"}>BEGINS_WITH</Option>
                                            <Option value={"ENDS_WITH"}>ENDS_WITH</Option>
                                            <Option value={"CONTAIN"}>CONTAIN</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: '17vw'}}
                                        name={[f.Name, 'value']}
                                        key={[f.Name, 'value'].join('#')}
                                        fieldKey={[f.Name, 'value']}>
                                        {
                                            f.Type === 'boolean' ?
                                                <Switch key={f.Name + '#value#switch'} onChange={async (event) => {
                                                    await this.updateFormFieldValue(f.Name, event ? 'true' : 'false')
                                                }}/> : null
                                        }
                                        {
                                            f.Type === 'string' ?
                                                <Input key={f.Name + '#value#input'}
                                                       value={this.getInitialFormData(f.Name).value as string}
                                                       onChange={async (event) => {
                                                           await this.updateFormFieldValue(f.Name, event.target.value)
                                                       }}/> : null
                                        }
                                        {
                                            f.Type === 'timestamp' ?
                                                <DatePicker key={f.Name + '#value#date_picker'} showTime
                                                            onChange={async (value, dateString) => {
                                                                await this.updateFormFieldValue(f.Name, dateString)
                                                            }}/> : null
                                        }
                                        {
                                            f.Type === 'int' ? <InputNumber key={f.Name + '#value#input_number'}
                                                                            onChange={async (value) => {
                                                                                const v = value ? value.toString() : undefined
                                                                                await this.updateFormFieldValue(f.Name, v)
                                                                            }}/> : null
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        name={[f.Name, 'type']}
                                        initialValue={f.Type}
                                        style={{display: 'none'}}>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={async () => {
                                        await this.removeFilter(f.Name)
                                    }}/>
                                </Space>
                            })
                        }
                    </Form>
                    {
                        !this.state.loading && this.state.tableItems ?
                            <LogsTable items={this.state.tableItems}
                                       projectId={this.props.projectId}/> :
                            <Skeleton/>
                    }
                </CustomSpinner>

            </>
        );
    }

}

LogsLayout.contextType = RootProjectContext

export default LogsLayout
