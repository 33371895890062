import React, {FC, useContext, useState} from "react";
import {Button, Divider, Drawer, Form, notification, Popconfirm, Select, Space, Table, Tag} from "antd";
import {RootProjectContext} from "../Contexts/RootProjectContext";
import {RootProjectClassMethods} from "../Api/APIService";
import {ScanJob} from "../Actions/Interfaces/IProject";


const ScanJobsLayout: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAddJob, setShowAddJob] = useState<boolean>(false)
    const [adding, setAdding] = useState<boolean>(false)
    const [stopping, setStopping] = useState<string>()
    const [form] = Form.useForm()

    const addJob = async (values: any) => {
        setAdding(true)
        try {
            await ctx.instance!.call<any>({
                method: RootProjectClassMethods.addScanJob,
                body: values
            })
            form.resetFields()
            setShowAddJob(false)
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message || "Error"
                })
            }
        }
        setAdding(false)
    }

    const stopJob = async (jobId: string) => {
        setStopping(jobId)
        try {
            await ctx.instance!.call<any>({
                method: RootProjectClassMethods.stopScanJob,
                body: {
                    jobId
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message || "Error"
                })
            }
        }
        setStopping(undefined)
    }

    return (
        <>
            <Space>
                <Button loading={adding} type={"primary"} onClick={() => {
                    setShowAddJob(true)
                }}> Add Job </Button>
            </Space>
            <Divider dashed/>
            <Drawer width={600} title="Add Job" placement="right" visible={showAddJob}
                    onClose={() => {
                        setShowAddJob(false)
                    }}
                    extra={<Button loading={adding} type={"primary"} htmlType={"submit"} form={"add_job"}>Add</Button>}
            >
                <Form
                    id={"add_job"}
                    form={form}
                    layout="vertical"
                    onFinish={addJob}
                >
                    <Form.Item
                        label="Class Id"
                        name={"classId"}
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Class"
                        >
                            {
                                (ctx.detail?.classes || []).map(c => {
                                    return <Select.Option value={c.classId}>{c.classId}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Destinations"
                        name={"destinationIds"}
                    >
                        <Select
                            mode={"multiple"}
                            allowClear
                            placeholder="Select Destination(s)"
                        >
                            {
                                (ctx.detail?.projectConfig.stateStreamTargets || []).map(t => {
                                    return <Select.Option value={t.id}>{t.id}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
            <Table dataSource={ctx.detail?.scanJobs || []} columns={[
                {
                    title: "Job Id",
                    dataIndex: "jobId"
                },
                {
                    title: "Class Id",
                    dataIndex: "classId"
                },
                {
                    title: "Status",
                    dataIndex: "status"
                },
                {
                    title: "Total Scanned",
                    dataIndex: "totalScanned"
                },
                {
                    title: "Destinations",
                    render: (item: ScanJob)=>{
                        return item.destinationIds.map(id=>{
                            return <Tag>{id}</Tag>
                        })
                    }
                },
                {
                    title: "",
                    render: (item: ScanJob)=>{
                        return item.status === "IN_PROGRESS" ? <Popconfirm
                            title="Are you sure to stop this job?"
                            onConfirm={async ()=>{ await stopJob(item.jobId)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button loading={stopping === item.jobId} danger>Stop</Button>
                        </Popconfirm> : null
                    }
                },
            ]}/>
        </>
    )
}

export default ScanJobsLayout
