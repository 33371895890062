import React, {Component} from "react";
import {Button, Descriptions, Divider, message, notification, Popconfirm} from "antd";
import {IProjectContext, RootProjectContext} from "../../Contexts/RootProjectContext";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../../Api/APIService";
import CustomSpinner from "../../Components/CustomSpinner";

interface Props {
}

interface State {
    loading: boolean
}

class SecretsLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
        this.regenerateSecret = this.regenerateSecret.bind(this)
    }

    componentDidMount() {
        this.ctx = this.context
    }

    async regenerateSecret(type: 'access' | 'refresh' | 'custom', classInstance: RetterCloudObject) {
        this.setState({
            loading: true
        })
        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.resetSecrets,
                body: {
                    secrets: [type]
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        this.setState({
            loading: false
        })
    }

    async copySecret(type: 'access' | 'refresh' | 'custom') {
        let secret = ''
        switch (type) {
            case "access":
                secret = this.ctx?.detail?.projectConfig.accessTokenSecret!
                break
            case "refresh":
                secret = this.ctx?.detail?.projectConfig.refreshTokenSecret!
                break
            case "custom":
                secret = this.ctx?.detail?.projectConfig.customTokenSecret!
                break
        }
        await navigator.clipboard.writeText(secret)
        message.success('Secret id copied!');
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <>
                            <CustomSpinner spinning={this.state.loading}>
                                <Descriptions
                                    bordered
                                    size={'middle'}
                                >
                                    <Descriptions.Item span={9} label={<>Access Secret Key </>}>
                                        {ctx.detail?.projectConfig.accessTokenSecret!.substr(0, 7)}{new Array(ctx.detail!.projectConfig!.accessTokenSecret!.length - 7).fill('*').join('')}<Button
                                        type={'link'}
                                        onClick={async () => {
                                            await this.copySecret('access')
                                        }}>Copy</Button>
                                        <Popconfirm
                                            title="Are you sure to regenerate access secret key?"
                                            onConfirm={async () => {
                                                await this.regenerateSecret('access', ctx.instance!)
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={'link'}> Regenerate Secret Key</Button>
                                        </Popconfirm>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider dashed></Divider>
                                <Descriptions
                                    bordered
                                    size={'middle'}
                                >

                                    <Descriptions.Item span={9} label={<>Refresh Secret Key </>}>
                                        {ctx.detail?.projectConfig.refreshTokenSecret!.substr(0, 7)}{new Array(ctx.detail!.projectConfig!.refreshTokenSecret!.length - 7).fill('*').join('')}<Button
                                        type={'link'}
                                        onClick={async () => {
                                            await this.copySecret('refresh')
                                        }}>Copy</Button>
                                        <Popconfirm
                                            title="Are you sure to regenerate refresh secret key?"
                                            onConfirm={async () => {
                                                await this.regenerateSecret('refresh', ctx.instance!)
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={'link'}> Regenerate Secret Key</Button>
                                        </Popconfirm>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider dashed></Divider>
                                <Descriptions
                                    bordered
                                    size={'middle'}
                                >

                                    <Descriptions.Item span={9} label={<>Custom Secret Key </>}>
                                        {ctx.detail?.projectConfig.customTokenSecret!.substr(0, 7)}{new Array(ctx.detail!.projectConfig!.customTokenSecret!.length - 7).fill('*').join('')}<Button
                                        type={'link'}
                                        onClick={async () => {
                                            await this.copySecret('custom')
                                        }}>Copy</Button>
                                        <Popconfirm
                                            title="Are you sure to regenerate refresh secret key?"
                                            onConfirm={async () => {
                                                await this.regenerateSecret('custom', ctx.instance!)
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={'link'}> Regenerate Secret Key</Button>
                                        </Popconfirm>
                                    </Descriptions.Item>
                                </Descriptions>
                            </CustomSpinner>
                        </>
                    )}
                </RootProjectContext.Consumer>

            </>
        );
    }
}

SecretsLayout.contextType = RootProjectContext

export default SecretsLayout