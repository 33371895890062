import React, {Component} from "react";
import {APIService} from "../Api/APIService";
import logo from "../logo.svg";
import {Button, Dropdown, Image, Menu, PageHeader, Spin, Typography} from "antd";
import {BrowserRouter as Router, Link, Route, RouteComponentProps, Switch} from "react-router-dom";
import ProjectDetailLayout from "./ProjectDetailLayout";
import StoreLayout from "./StoreLayout";
import ServiceDetailLayout from "./ServiceLayouts/ServiceDetailLayout";
import StoreServiceDetail from "./StoreLayouts/StoreServiceDetail";
import BillingAccountList from "./BillingAccountLayouts/BillingAccountList";
import BillingAccountDetail from "./BillingAccountLayouts/BillingAccountDetail";
import {HomePage} from "./HomePage";
import {ServiceList} from "./ServiceLayouts/ServiceList";
import UserSettingsModal from "../Modals/UserSettingsModal";
import {topMenuStore} from "../Actions/Actions";
import {
    CreditCardOutlined,
    DownOutlined,
    LoadingOutlined,
    LogoutOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {RetterCloudObject} from "@retter/sdk";
import {userStore} from "../Actions/Stores/UserStore";
import {UserProviderContext} from "../Contexts/UserProviderContext";
import {InviteUserModal} from "../Modals/InviteUserModal";
import conf from "../config/autoGenConfig";
import ChangeProjectSubscriptionModal from "./AdminSettings/ChangeProjectSubscriptionModal";

const {Title} = Typography

interface Props {
    apiService: APIService
    userInstance?: RetterCloudObject
}

interface State {
    showSettings: boolean
    topMenuTitleContent: React.ReactChild
    topMenuExtra: any[]
    userDetail?: any
    loading: boolean
    invitesVisible: boolean
    showChangeProjectSubscriptionSettings: boolean
}

class MainLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showSettings: false,
            topMenuTitleContent: '',
            topMenuExtra: [],
            loading: true,
            invitesVisible: false,
            showChangeProjectSubscriptionSettings: false
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
        topMenuStore.subscribe(async () => {
            const state = topMenuStore.getState()
            if (state) {
                if (state.data.extraMenu) {
                    this.setState({
                        topMenuExtra: state.data.extraMenu || [],
                    })
                }
                if (state.data.title) {
                    this.setState({
                        topMenuTitleContent: state.data.title
                    })
                }
            }
        })
    }

    componentDidMount() {
        userStore.subscribe(() => {
            const state = userStore.getState()
            this.setState({
                userDetail: state.data,
                loading: false
            })
        })
    }


    async handleLogoutClick() {
        this.setState({
            loading: true
        })
        await this.props.apiService.rootRbsSdk.signOut()
        localStorage.clear()
        this.setState({
            loading: false
        })
        window.location.href = '/'
    }

    async handleServicesLink() {

    }

    showSettings = (val: boolean) => {
        this.setState({
            showSettings: val
        })
    }

    showChangeProjectSubscriptionSettings = (val: boolean) => {
        this.setState({
            showChangeProjectSubscriptionSettings: val
        })
    }

    settingsCancelCallback = () => {
        this.setState({
            showSettings: false
        })
    }

    inviteModelClosed = () => {
        this.setState({
            invitesVisible: false
        })
    }

    render() {
        return (
            <>
                <Spin size={"large"} indicator={<Spin indicator={<LoadingOutlined spin/>}/>}
                      spinning={this.state.loading}>
                    <Router>
                        <div>
                            <Switch>
                                <PageHeader
                                    className="site-page-header"
                                    extra={[
                                        ...this.state.topMenuExtra,
                                        <Dropdown overlay={
                                            <Menu>
                                                {
                                                    this.state.userDetail ? <>
                                                        <Menu.ItemGroup key="g1"
                                                                        title={this.state.userDetail.email}>
                                                        </Menu.ItemGroup>
                                                    </> : <>loading</>
                                                }
                                                <Menu.Item icon={<LogoutOutlined/>} key="logout"
                                                           onClick={this.handleLogoutClick}>Logout</Menu.Item>
                                                <Menu.Item icon={<SettingOutlined/>} key="settings" onClick={() => {
                                                    this.showSettings(true)
                                                }}>Settings</Menu.Item>
                                                <UserProviderContext.Consumer>
                                                    {(ctx) => (
                                                        ctx.isRootProjectMember ? <>
                                                            <Menu.Item icon={<CreditCardOutlined/>} key="settings"
                                                                       onClick={() => {
                                                                           this.showChangeProjectSubscriptionSettings(true)
                                                                       }}>Change Project Subscription</Menu.Item>
                                                        </> : null
                                                    )}
                                                </UserProviderContext.Consumer>
                                                <Menu.Divider/>
                                                <Menu.Item icon={<UsergroupAddOutlined/>}
                                                           onClick={() => {
                                                               this.setState({invitesVisible: true})
                                                           }}
                                                           key="invite">
                                                    Invitations
                                                </Menu.Item>
                                                <Menu.ItemGroup key="version"
                                                                title={`version ${conf.version}`}>
                                                </Menu.ItemGroup>
                                            </Menu>
                                        }>
                                            <Button icon={<UserOutlined/>} type={'default'}
                                                    className="ant-dropdown-link"
                                                    onClick={e => e.preventDefault()}>
                                                <DownOutlined/>
                                            </Button>
                                        </Dropdown>
                                    ]}
                                    title={
                                        <>
                                            <div className={'menu-logo'}>
                                                <Link to='/'>
                                                    <Image
                                                        preview={false}
                                                        src={logo}
                                                    />
                                                </Link>
                                            </div>
                                        </>
                                    }
                                    subTitle={<Title level={5}>{this.state.topMenuTitleContent}</Title>}
                                >

                                    <Route exact path="/" render={(props: RouteComponentProps<any>) => {
                                        return <HomePage apiService={this.props.apiService}
                                                         routeComponentProps={props}/>
                                    }}>
                                    </Route>
                                    <Route exact path="/Services">
                                        <ServiceList apiService={this.props.apiService}/>
                                    </Route>
                                    <Route exact path="/Store">
                                        <StoreLayout apiService={this.props.apiService}/>
                                    </Route>
                                    <Route exact path="/billing">
                                        <BillingAccountList apiService={this.props.apiService}/>
                                    </Route>
                                    <Route path="/project/:projectId/:tab"
                                           render={(props: RouteComponentProps<{ projectId: string, tab: string }>) => {
                                               return <ProjectDetailLayout apiService={this.props.apiService}
                                                                           routeComponentProps={props}/>
                                           }}>
                                    </Route>
                                    <Route path="/service/:serviceId"
                                           render={(props: RouteComponentProps<{ serviceId: string }>) => {
                                               return <ServiceDetailLayout apiService={this.props.apiService}
                                                                           routeComponentProps={props}/>
                                           }}>
                                    </Route>
                                    <Route path="/store/:serviceId"
                                           render={(props: RouteComponentProps<{ serviceId: string }>) => {
                                               return <StoreServiceDetail apiService={this.props.apiService}
                                                                          routeComponentProps={props}/>
                                           }}>
                                    </Route>
                                    <Route path="/billing/:billingAccountId"
                                           render={(props: RouteComponentProps<{ billingAccountId: string }>) => {
                                               return <BillingAccountDetail apiService={this.props.apiService}
                                                                            routeComponentProps={props}/>
                                           }}>
                                    </Route>
                                </PageHeader>
                            </Switch>
                        </div>
                    </Router>
                    {
                        this.state.invitesVisible ? <InviteUserModal
                            inviteModelClosed={this.inviteModelClosed}
                            visible={this.state.invitesVisible}
                            userInstance={this.props.userInstance}/> : null
                    }
                    <UserSettingsModal visible={this.state.showSettings} cancelCallback={this.settingsCancelCallback}/>
                    <UserProviderContext.Consumer>
                        {(ctx) => (
                            ctx.isRootProjectMember ?
                        <ChangeProjectSubscriptionModal visible={this.state.showChangeProjectSubscriptionSettings}
                            cancelCallback={
                                () => {
                                    this.setState({
                                        showChangeProjectSubscriptionSettings: false
                                    })
                                }
                            } rootRbsSdk={this.props.apiService.rootRbsSdk}/>
                            : null
                        )}
                    </UserProviderContext.Consumer>
                </Spin>
            </>
        )
    }

}

MainLayout.contextType = UserProviderContext
export default MainLayout
