import React, {Component} from "react";
import {
    Breadcrumb,
    Button,
    Descriptions,
    Divider,
    Image,
    Modal,
    Switch,
    Tag,
    Timeline,
    Tooltip,
    Typography
} from "antd";
import CustomSpinner from "../Components/CustomSpinner";
import moment from "moment";
import {InfoCircleOutlined} from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import CosMethodResponseLogs from "../Layouts/CosLayouts/Components/CosMethodResponseLogs";
import {IProjectContext, RootProjectContext} from "../Contexts/RootProjectContext";
import {RootProjectClassMethods} from "../Api/APIService";

const decompress = require('brotli/decompress');
const prettyBytes = require("pretty-bytes")
const prettyMilliseconds = require("pretty-ms")
const {Text} = Typography;


interface Props {
    logSummary: any
    projectId: string
    show?: boolean
    onCancel?: Function
}

interface State {
    loading: boolean
    visible: boolean
    logDetail?: any
    req?: any
    res?: any
    routeFrom: any
    routeTo: any
    imageResponse?: string
}

class LogDetailModal extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            visible: !!props.show,
            routeFrom: '',
            routeTo: '',
        }
        this.showModal = this.showModal.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getDetail = this.getDetail.bind(this)
    }

    async getDetail() {
        if(!this.ctx) throw new Error('ctx not found')
        this.setState({
            loading: true
        })

        const res = await this.ctx.instance?.call<any>({
            method: RootProjectClassMethods.getLogDetails,
            body: {
                logId: this.props.logSummary.logid,
                year: this.props.logSummary.year,
                month: this.props.logSummary.month,
                day: this.props.logSummary.day,
                hour: this.props.logSummary.hour,
            }
        })
        if (res) {
            const result= res.data
            this.setState({
                logDetail: result
            })
            if (result.userid) {
                this.setState({
                    routeFrom: <><Text>Id: {result.userid}</Text><Text
                        code>Role: {result.identity}</Text></>,
                    routeTo: <><Text code>Id: {result.serviceid}</Text></>
                })
            } else {
                this.setState({
                    routeFrom: <><Text>Id: {result.identity}</Text></>,
                    routeTo: <><Text>Id: {result.serviceid}</Text></>
                })
            }
            if (result.classid) {
                this.setState({
                    routeTo: <>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Class
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a target={'_blank'}
                                   href={`/project/${result.projectid}/Classes/${result.classid}`}>{result.classid}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a target={'_blank'}
                                   href={`/project/${result.projectid}/Classes/${result.classid}/instance/${result.instanceid}`}>{result.instanceid}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {result.methodname}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </>
                })
            }
            this.prepareData(result)
        }
        this.setState({
            loading: false
        })
    }

    async componentDidMount() {
        this.ctx = this.context
        await this.getDetail()
    }

    prepareData = (result: any) => {
        if (result) {
            let req: any
            let res: any
            req = result.req && typeof result.req === "string" ?
                JSON.parse(Buffer.from(decompress(Buffer.from(result.req, 'base64'))).toString('utf-8')) : ''
            res = result.res && typeof result.res === "string" ?
                JSON.parse(Buffer.from(decompress(Buffer.from(result.res, 'base64'))).toString('utf-8')) : ''
            if (req.body && typeof req.body === "string") {
                let body = req.body
                try {
                    body = JSON.parse(body)
                } catch (e) {
                }
                req.body = body
            }
            if (res && res.body && res.headers) {
                const typ = res.headers["Content-Type"] || res.headers["content-type"]
                if (typ === 'application/json') {
                    try {
                        res.body = JSON.parse(res.body)
                    } catch (e) {
                    }
                }
            }
            const maybeImage = res ?
                req
                && req.headers
                && req.headers.accept
                && res.type
                && res.type === 'Buffer'
                && res.data
                && Array.isArray(res.data) : false
            if (maybeImage) {
                let imageType = undefined
                for (const img of ['image/jpg', 'image/jpg', 'image/png', 'image/webp']) {
                    if (req.headers.accept.split(',')[0]!.includes(img)) {
                        imageType = img
                        break
                    }
                    if (!imageType && req.body && req.body.contentType && req.body.contentType.includes(img)) {
                        imageType = img
                        break
                    }
                }
                if (imageType) {
                    this.setState({
                        imageResponse: `data:${imageType};base64,${Buffer.from(res.data).toString('base64')}`
                    })
                }
            } else {
                try {
                    res = JSON.parse(res)
                } catch (e) {

                }
            }
            this.setState({
                res,
                req
            })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };


    handleCancel = async () => {
        this.setState({visible: false});
        if (this.props.onCancel)
            await this.props.onCancel()
    };


    render() {
        return (
            <>
                <Modal
                    width={1000}
                    visible={this.state.visible}
                    title={`Log Detail - ${this.props.logSummary.logid}`}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel} loading={this.state.loading}>
                            Cancel
                        </Button>
                    ]}
                >
                    <CustomSpinner spinning={this.state.loading}>
                        {
                            this.state.logDetail ? <>
                                <Timeline>
                                    <Timeline.Item>{this.state.routeFrom}</Timeline.Item>
                                    <Timeline.Item
                                        color={
                                            this.state.logDetail.statuscode >= 200 && this.state.logDetail.statuscode < 400 ?
                                                'green' : 'red'
                                        }
                                    >{this.state.routeTo}</Timeline.Item>
                                </Timeline>
                                <Descriptions bordered>
                                    <Descriptions.Item
                                        span={3}
                                        label="statuscode">
                                        {
                                            this.state.logDetail.statuscode >= 200 && this.state.logDetail.statuscode < 400 ?
                                                <Text type="success">{this.state.logDetail.statuscode}</Text> :
                                                <Text type="danger">{this.state.logDetail.statuscode}</Text>
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={<>requesttimeepoch <Tooltip title="Local time zone">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{moment(this.state.logDetail.requesttimeepoch.replace(' ', 'T') + 'Z')
                                        .format('YYYY-MM-DD HH:mm:ss.SSS')}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={<>responsetimeepoch <Tooltip title="Local time zone">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{moment(this.state.logDetail.responsetimeepoch.replace(' ', 'T') + 'Z')
                                        .format('YYYY-MM-DD HH:mm:ss.SSS')}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="identity">{this.state.logDetail.identity}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="userid">{this.state.logDetail.userid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="classid">{this.state.logDetail.classid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="instanceId">{this.state.logDetail.instanceid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="methodName">{this.state.logDetail.methodname}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="refererClassId">{this.state.logDetail.refererclassid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="refererInstanceId">{this.state.logDetail.refererinstanceid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="refererMethodName">{this.state.logDetail.referermethodname}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="refererUserId">{this.state.logDetail.refereruserid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="refererIdentity">{this.state.logDetail.refereridentity}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="logid">{this.state.logDetail.logid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="requestid">{this.state.logDetail.requestid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="sessionid">{this.state.logDetail.sessionid}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={1}
                                        label="culture">{this.state.logDetail.culture}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={1}
                                        label="platform">{this.state.logDetail.platform}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={1}
                                        label="cache">{this.state.logDetail.cache ?
                                        <Switch size="small" disabled defaultChecked/> :
                                        <Switch size="small" disabled/>}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label="durationinmilliseconds">
                                        {prettyMilliseconds(this.state.logDetail.durationinmilliseconds || 0)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label="executiondurationinmilliseconds">
                                        {prettyMilliseconds(this.state.logDetail.executiondurationinmilliseconds || 0)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientbrowsername'}>{this.state.logDetail.clientbrowsername}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientbrowserversion'}>{this.state.logDetail.clientbrowserversion}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientcpuarchitecture'}>{this.state.logDetail.clientcpuarchitecture}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientdevicemodel'}>{this.state.logDetail.clientdevicemodel}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientdevicetype'}>{this.state.logDetail.clientdevicetype}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientdevicevendor'}>{this.state.logDetail.clientdevicevendor}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientenginename'}>{this.state.logDetail.clientenginename}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientengineversion'}>{this.state.logDetail.clientengineversion}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientosname'}>{this.state.logDetail.clientosname}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientosversion'}>{this.state.logDetail.clientosversion}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={'clientuseragent'}>{this.state.logDetail.clientuseragent}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label="sourceip">{this.state.logDetail.sourceip}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label="reqsize">{prettyBytes(this.state.logDetail.reqsize)}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label="ressize">{prettyBytes(this.state.logDetail.ressize)}</Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label={<>year <Tooltip title="UTC">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{this.state.logDetail.year}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label={<>month <Tooltip title="UTC">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{this.state.logDetail.month}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label={<>day <Tooltip title="UTC">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{this.state.logDetail.day}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={2}
                                        label={<>hour <Tooltip title="UTC">
                                            <span><InfoCircleOutlined/></span>
                                        </Tooltip></>}>{this.state.logDetail.hour}
                                    </Descriptions.Item>
                                    {
                                        Object.keys(this.state.logDetail).map(k => {
                                            if (new RegExp('^(param[0-9])$', 'g').test(k)) {
                                                return <Descriptions.Item
                                                    span={3}
                                                    label={k}>{this.state.logDetail[k]}</Descriptions.Item>
                                            }
                                        })
                                    }
                                </Descriptions>
                                <Divider dashed/>
                                <Divider orientation="left" plain>
                                    <Text strong>Request</Text>
                                </Divider>
                                {
                                    this.state.req ? <Editor options={{readOnly: true}}
                                                             height={'40vh'}
                                                             language={'json'}
                                                             defaultValue={JSON.stringify(this.state.req, null, 2)}/> : 'No request'
                                }
                                <Divider dashed/>
                                <Divider orientation="left" plain>
                                    <Text strong>Response</Text>
                                </Divider>
                                {
                                    this.state.res ? this.state.imageResponse ?
                                        <Image src={this.state.imageResponse}/> :
                                        <Editor options={{readOnly: true}} height={'40vh'} language={'json'}
                                                defaultValue={JSON.stringify(this.state.res, null, 2)}/> : 'No response'
                                }
                                {
                                    this.state.logDetail.classid &&
                                    this.state.logDetail.requestid ? <>
                                        <Divider orientation="left" plain>
                                            <Text strong>Method Response Logs</Text>
                                        </Divider>
                                        <CosMethodResponseLogs
                                            classId={this.state.logDetail.classid}
                                            projectId={this.props.projectId}
                                            requestId={this.state.logDetail.requestid}/>
                                    </> : null
                                }


                            </> : null
                        }
                    </CustomSpinner>
                </Modal>
            </>
        );
    }
}

LogDetailModal.contextType = RootProjectContext

export default LogDetailModal
