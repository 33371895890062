import React, {Component} from "react";
import {Button, Form, Modal, Select, Spin} from "antd";
import {APIService, ConsoleServiceActions} from "../Api/APIService";

const {Option} = Select;

interface Props {
    key: string
    member: { email: string, memberId: string, currentRoles: string[], updateToken: string }
    apiService: APIService
    serviceId: string
    successCallback: Function
}

interface State {
    loading: boolean
    visible: boolean
    developerActions: string[]
    selectedActions: string[]
}

export class EditServiceMemberRoles extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            loading: false,
            visible: false,
            developerActions: [],
            selectedActions: props.member.currentRoles
        }
        this.updateRoles = this.updateRoles.bind(this)
        this.selectRolesChange = this.selectRolesChange.bind(this)
    }

    async componentDidMount() {
        this.setState({
            developerActions: Object.values(ConsoleServiceActions)
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async updateRoles(roles: string[]) {
        this.setState({loading: true});
        const resp = await this.apiService.updateServiceMemberRoles(this.props.serviceId, this.props.member.memberId, roles, this.props.member.updateToken)
        if (resp)
            await this.props.successCallback()
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async (values: any) => {
        const {roles} = values
        await this.updateRoles(roles)

    };

    selectRolesChange(values: string[], option: any) {
        this.setState({
            selectedActions: values
        })
    }

    render() {
        const {visible, loading} = this.state;

        return (
            <>
                <Button type="link" onClick={this.showModal}>
                    Edit Roles
                </Button>
                <Modal
                    key={this.props.key}
                    visible={visible}
                    title="Edit Service Member Roles"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type={"link"} onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>,
                        <Button type="primary" form={"edit_project_member"} htmlType="submit">
                            Update
                        </Button>
                    ]}
                >
                    <Spin spinning={loading}>
                        <h3>{this.props.member.email}</h3>
                        <Form
                            layout={'vertical'}
                            id={'edit_project_member'}
                            name="basic"
                            initialValues={{remember: true}}
                            onFinish={this.onFinish}
                        >
                            <Form.Item
                                label="Actions"
                                name="roles"
                                rules={[{required: true, message: 'Please select developer actions'}]}>
                                <Select
                                    onChange={this.selectRolesChange}
                                    mode="multiple"
                                    defaultValue={this.state.selectedActions.map(r => {
                                        return r
                                    })}
                                    value={this.state.selectedActions.map(r => {
                                        return r
                                    })}
                                    allowClear
                                    placeholder="Add action"
                                >
                                    {
                                        this.state.developerActions ? this.state.developerActions.map(r =>
                                            <Option value={r} data={r}>{r}</Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
}