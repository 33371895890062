export interface IProjectConfig {
    accessTokenSecret: string;
    refreshTokenSecret: string;
}

export interface IProjectRolePermissions {
    sends: string[];
    receives: string[];
}

export interface IProjectRole {
    key: string;
    roleName: string;
    delegatedBy: string[];
    permissions: IProjectRolePermissions;
}

export interface IProjectMember {
    userId: string;
    email: string;
    role: string;
}

export enum StateStreamTargetType {
    Elasticsearch = "Elasticsearch",
    Firestore = "Firestore",
    Http = "Http"
}

export enum TracingType {
    XRAY= "XRAY"
}

export interface StateStreamRetryConfig{
    delay: number
    count: number
    rate: number
}

export interface StateStreamTarget {
    type: StateStreamTargetType
    id: string
    mappingId: string
    credentials: any
    retryConfig?: StateStreamRetryConfig
    transformationTemplate?: string
}

export interface ScanJob{
    jobId: string
    classId: string
    destinationIds: string[]
    status: string
    executionArn: string
    totalScanned: number
}

export interface IProjectConfig {
    refreshTokenSecret: string;
    accessTokenSecret: string;
    customTokenSecret: string;
    tracingAdapters?: TracingAdaptorItem[];
    status?: {
        type: 'deactivated' | 'active'
    }
    logAdapters: LogAdapterItem[]
    stateStreamTargets?: StateStreamTarget[]
}

export interface TracingAdaptorItem{
    type: string
}

export interface LogAdapterItem {
    endpoint: string
    password: string
    type: string
    username: string
}

export interface IProjectClass {
    classId: string;
}

export interface IProjectDetail {
    members: IProjectMember[];
    projectConfig: IProjectConfig;
    attachedRoles: any[];
    classes: IProjectClass[];
    alias: string;
    envVars: { [key: string]: string };
    projectRoles: IProjectRole[];
    layers?: { [dependencyName: string]: { version: string, hash?: string } }
    scanJobs?: ScanJob[]
}
