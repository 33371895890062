import React, {Component} from "react";
import "./App.less";
import {APIService, HttpAPIService, RootProjectClassEnums} from "./Api/APIService";
import WelcomeLayout from "./Layouts/WelcomeLayout";
import CustomSpinner from "./Components/CustomSpinner";
import RBS, {RetterAuthChangedEvent, RetterAuthStatus, RetterCloudObject} from "@retter/sdk";
import autoGenConfig from "./config/autoGenConfig";
import {IUserStoreDataItems, userStore, UserStoreActionTypes} from "./Actions/Stores/UserStore";
import MainLayout from "./Layouts/MainLayout";
import {GlobalHelpers} from "./GlobalHelpers";
import {IUserProviderContext, UserProviderContext} from "./Contexts/UserProviderContext";

interface AppProps {
}

export interface State {
    authState?: RetterAuthStatus
    error?: any;
    message?: string;
    loading: boolean;
    projects: any;
    userInstance?: RetterCloudObject
    rootRbsSdk: RBS
    userProviderContext?: IUserProviderContext
}

export default class App extends Component<AppProps, State> {
    private readonly apiService: APIService;
    readonly rootSdk: RBS

    constructor(props: AppProps, rootSdk: RBS) {
        super(props);
        this.rootSdk = rootSdk

        if (!autoGenConfig.rootProjectId) throw new Error('Root project id is not defined')

        const sdk = GlobalHelpers.getRbsInstanceByProjectId(autoGenConfig.rootProjectId)

        this.state = {
            projects: false,
            loading: true,
            rootRbsSdk: sdk,
            userProviderContext: {
                rootRbsSdk: sdk
            }
        };

        sdk.authStatus.subscribe(async (eventAuth: RetterAuthChangedEvent) => {
            switch (eventAuth.authStatus) {
                case RetterAuthStatus.SIGNED_OUT:
                    this.setState({
                        authState: eventAuth.authStatus,
                        loading: false
                    })
                    break
                case RetterAuthStatus.SIGNED_IN:
                    const userInstance = await sdk.getCloudObject({
                        classId: RootProjectClassEnums.User,
                        instanceId: eventAuth.uid
                    })
                    this.setState({
                        userProviderContext: {
                            ...this.state.userProviderContext,
                            ...{instance: userInstance}
                        },
                        userInstance
                    })
                    userInstance.state?.public?.subscribe((event: any) => {
                        console.log('useri',event)
                        this.setState({
                            authState: eventAuth.authStatus
                        })
                        this.setState({
                            userProviderContext: {
                                ...this.state.userProviderContext,
                                ...{detail: event},
                                isRootProjectMember: !!(event as IUserStoreDataItems).myProjects.find(p=>p.projectId === 'root')
                            },
                            loading: false
                        })
                        userStore.dispatch({data: event, type: UserStoreActionTypes.CHANGED})
                    })
                    break
            }
        })
        this.apiService = new HttpAPIService(sdk);
    }

    render() {
        return (
            <CustomSpinner spinning={this.state.loading}>
                <div>
                    {this.state.authState === RetterAuthStatus.SIGNED_IN && this.state.userProviderContext ? (
                        <UserProviderContext.Provider value={this.state.userProviderContext!}>
                            <MainLayout userInstance={this.state.userInstance} apiService={this.apiService}/>
                        </UserProviderContext.Provider>
                    ) : (
                        <WelcomeLayout apiService={this.apiService}/>
                    )}
                </div>
            </CustomSpinner>
        );
    }
}
