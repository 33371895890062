import {Form} from "antd";
import React, {FC} from "react";
import Editor from "@monaco-editor/react";


const DestinationFirestoreForm: FC = (props) => {

    return (
        <>
            <Form.Item
                label="Credentials"
                name={"credentials"}
                rules={[{required: true, message: 'Please input credentials!'}]}
            >
                <Editor
                    key={'credentials'}
                    height={'50vh'}
                    language={'json'}
                />
            </Form.Item>
        </>
    )
}

export default DestinationFirestoreForm
