import React, {Component} from "react";
import {Badge, Button, Card, Progress, Table} from "antd";
import {IProjectContext, RootProjectContext} from "../Contexts/RootProjectContext";
import CustomSpinner from "../Components/CustomSpinner";
import {IDashboardResponse, Limits} from "../Interfaces/IDashboardResponse";
import {GlobalHelpers} from "../GlobalHelpers";

interface Metric {
    title: string
    percent: number
    usage: number
    limit: number
    content: any
}

interface Props {

}

interface State {
    loading: boolean
    metrics: Metric[]
    label: string
}

class DashboardLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            label: '',
            loading: true,
            metrics: []
        }
        this.getDashboard = this.getDashboard.bind(this)
    }

    async getDashboard() {
        this.setState({loading: true})
        const context = this.context as IProjectContext
        try {
            if (context.instance) {
                const result = await context.instance.call({
                    method: 'getDashboard'
                })
                if (result.status >= 200) {
                    const data = result.data as IDashboardResponse
                    const metrics: Metric[] = []
                    this.setState({
                        label: data.limits.label
                    })
                    Object.keys(data.limits).forEach(limitKey => {
                        const limit = data.limits[limitKey as keyof Limits] as number || 0
                        if (limitKey && !['id', 'label'].includes(limitKey) && limit > 0) {
                            const usage = data.usages[limitKey] || 0
                            let percent = (100 * usage) / limit
                            percent = Math.round(percent * 10) / 10
                            metrics.push({
                                title: GlobalHelpers.humanizeCamelCase(limitKey),
                                limit,
                                usage,
                                percent,
                                content: <>
                                    <Progress
                                        percent={percent}
                                        format={percent => {
                                            return percent + '%'
                                        }}
                                        strokeColor={percent <= 85 ? '#1890ff' : percent <= 95 ? '#fadb14' : '#f5222d'}
                                        status={percent >= 100 ? 'exception' : 'normal'}
                                    />
                                </>
                            })
                        }
                    })
                    this.setState({
                        metrics
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({loading: false})
    }

    async componentDidMount() {
        if (this.context) {
            await this.getDashboard()
        }
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Badge.Ribbon placement={'start'} color={'#faad14'} text={this.state.label}>
                        <Card title="" size="small">
                            <Table
                                scroll={{y: '85vh'}}
                                pagination={false}
                                columns={[
                                    {
                                        title: <>Metric <Button type={'link'} onClick={this.getDashboard}>Refresh</Button></>,
                                        dataIndex: 'title'
                                    },
                                    {
                                        title: 'Percent',
                                        dataIndex: 'content',
                                        sorter: (a, b) => a.percent - b.percent,
                                    },
                                    {
                                        title: 'Usage',
                                        dataIndex: 'usage',
                                        sorter: (a, b) => a.usage - b.usage,
                                    },
                                    {
                                        title: 'Limit',
                                        dataIndex: 'limit',
                                        sorter: (a, b) => a.limit - b.limit,
                                    }
                                ]}
                                dataSource={this.state.metrics}
                            />
                        </Card>
                    </Badge.Ribbon>
                </CustomSpinner>
            </>


        )
    }
}

DashboardLayout.contextType = RootProjectContext

export default DashboardLayout
