import React, {useState} from 'react';
import {Button, Divider, Form, Input} from "antd";
import {RetterCloudObject} from "@retter/sdk";
import {APIService} from "../Api/APIService";

interface Props {
    loading: boolean;
    onSignInPressed: () => void;
    userInstance?: RetterCloudObject
    apiService: APIService
    onConfirmPressed: (otp: string) => Promise<void>
}

interface Values {
    code: string;
}

const ConfirmSignUpForm = (props: Props) => {
    let [loading, setLoading] = useState(false);

    const onFinish = async (values: Values) => {
        await props.onConfirmPressed(values.code)
    }

    return (
        <Form
            size={"large"}
            name="confirm-sign-in"
            className="login-confirm-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            <Form.Item
                name="code"
                rules={[
                    {required: true, message: "Please input your Code!"}
                ]}
            >
                <Input
                    placeholder="Code"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    style={{marginBottom: 10}}
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    Confirm Sign In
                </Button>
                <Divider plain>Or</Divider>
                <span
                    onClick={props.onSignInPressed}
                    className="login-form-forgot"
                    style={{color: '#1890ff', cursor: 'pointer'}}
                >
          Log In
        </span>
            </Form.Item>
        </Form>
    )
}

export default ConfirmSignUpForm;
