import React, {Component} from "react";
import {Breadcrumb, Empty, Layout, PageHeader, Table} from 'antd';
import {APIService} from "../../../Api/APIService";
import {CreateNewProjectModal} from "../../../Modals/CreateNewProjectModal";
import CustomSpinner from "../../../Components/CustomSpinner";
import emptySvg from "../../../empty.svg"
import {CreateNewServiceModal} from "../../../Modals/CreateNewServiceModal";
import {ActionTypes, developerServiceStore} from "../../../Actions/Actions";
import {Link} from "react-router-dom";
import {LiveService} from "../../../Interfaces/Service";


const {Content} = Layout;


interface Props {
    apiService: APIService
}

interface State {
    services: any
    projectDetail?: any
    adminEmail?: string
    adminId?: string
}

export default class ServicesLayout extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        const admin = JSON.parse(localStorage.getItem('user')!)
        this.apiService = props.apiService

        this.state = {
            services: false,
            adminEmail: admin ? admin.email : undefined,
            adminId: admin ? admin.username : undefined
        }

        this.getAllServices = this.getAllServices.bind(this)
    }

    async componentDidMount() {
        this.setState({services: 'loading'})
        await this.getAllServices()
        developerServiceStore.subscribe(async () => {
            if (developerServiceStore.getState().state === ActionTypes.DEVELOPER_SERVICE.types.CREATED) {
                await this.getAllServices()
            }
        })
    }

    private async getAllServices() {
        this.setState({services: 'loading'})
        const services = await this.apiService.getServices()
        this.setState({services: services})
    }

    render() {
        return (
            <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                </Breadcrumb>

                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                    }}
                >
                    <PageHeader
                        className="site-page-header"
                        title="Services"
                        subTitle="Created services"
                        extra={[
                            <CreateNewServiceModal apiService={this.apiService}/>
                        ]}
                    >
                        <CustomSpinner spinning={this.state.services === "loading"}>
                            <div className="site-card-wrapper">
                                {
                                    Array.isArray(this.state.services) && this.state.services.length > 0 ?
                                        <>
                                            <Table
                                                size={"middle"}
                                                scroll={{x: 200}}
                                                pagination={{defaultPageSize: 50}}
                                                dataSource={this.state.services}
                                                columns={
                                                    [
                                                        {
                                                            title: "Service Name",
                                                            render: (service: LiveService) => {
                                                                return <Link
                                                                    to={`/service/${service.manifest.id}/Overview`}>
                                                                    {service.manifest.name}
                                                                </Link>
                                                            }
                                                        },
                                                        {
                                                            title: "Service Id",
                                                            render: (service: LiveService) => {
                                                                return <>
                                                                    {service.manifest.id}
                                                                </>
                                                            }
                                                        },
                                                        {
                                                            title: "Send Actions",
                                                            render: (service: LiveService) => {
                                                                return <>
                                                                    {service.manifest.actions.sends ? service.manifest.actions.sends.length : 0}
                                                                </>
                                                            }
                                                        },
                                                        {
                                                            title: "Receive Actions",
                                                            render: (service: LiveService) => {
                                                                return <>
                                                                    {service.manifest.actions.receives ? service.manifest.actions.receives.length : 0}
                                                                </>
                                                            }
                                                        }
                                                    ]
                                                }/>
                                        </> :
                                        <Empty
                                            image={emptySvg}
                                            imageStyle={{
                                                height: 60,
                                            }}
                                            description={
                                                "No services found"
                                            }
                                        >
                                            <CreateNewServiceModal
                                                apiService={this.apiService}/>
                                        </Empty>
                                }
                            </div>
                        </CustomSpinner>
                    </PageHeader>
                </Content>
            </Layout>
        )
    }
}