import React, {FC} from 'react';
import {Form, Input} from 'antd';


interface Props {
    value?: any
}

const MemoryResultComponent: FC<Props> = (props) => {
    const [form] = Form.useForm<{ value?: any }>();
    form.setFieldsValue({
        value: props.value
    });

    return (
        <>
            <Form
                form={form}
                name="get_memory_result"
                layout="vertical"
                wrapperCol={{span: 16}}
                autoComplete="off"
            >
                <Form.Item
                    label="Value"
                    name="value"
                >
                    <Input.TextArea
                        readOnly
                        placeholder="Value"
                        rows={5}/>
                </Form.Item>
            </Form>
        </>
    );
};

export default MemoryResultComponent;
