import React, {FC, useContext} from "react";
import {Button, Descriptions, Divider, Drawer, Form, Input, notification, Popconfirm, Select, Table} from "antd";
import {RootProjectContext} from "../../Contexts/RootProjectContext";
import {LogAdapterItem} from "../../Actions/Interfaces/IProject";
import {RootProjectClassMethods} from "../../Api/APIService";


const LogAdapters: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAdd, setShowAdd] = React.useState(false)
    const [showDetail, setShowDetail] = React.useState(false)
    const [selectedAdapter, setSelectedAdapter] = React.useState<LogAdapterItem>()
    const [loading, setLoading] = React.useState(false)

    const addAdapter = async (values: any) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateLogAdaptors,
                body: {
                    logAdapters: (ctx.detail?.projectConfig.logAdapters || []).concat(values)
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        setShowAdd(false)
        setLoading(false)
    }

    const deleteAdapter = async (values: LogAdapterItem) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateLogAdaptors,
                body: {
                    logAdapters: (ctx.detail?.projectConfig.logAdapters || []).filter(item =>
                        !(item.endpoint === values.endpoint &&
                            item.type === values.type &&
                            item.username === values.username &&
                            item.password === values.password)
                    )
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        setShowDetail(false)
        setSelectedAdapter(undefined)
        setLoading(false)
    }

    return (
        <>
            <Button loading={loading} onClick={() => {
                setShowAdd(true)
            }}>Add Log Adapter</Button>
            <Divider dashed/>
            <Table dataSource={ctx.detail?.projectConfig.logAdapters || []}
                   columns={[
                       {
                           title: 'Endpoint',
                           render: (item: LogAdapterItem) => {
                               return <a onClick={() => {
                                   setSelectedAdapter(item)
                                   setShowDetail(true)
                               }}>{item.endpoint}</a>
                           }
                       },
                       {
                           title: 'Type',
                           dataIndex: 'type',
                       }
                   ]}/>
            <Drawer title="Detail" width={600} placement="right" onClose={() => {
                setShowDetail(false)
            }} visible={showDetail} extra={
                <Popconfirm
                    title="Are you sure to delete this adapter?"
                    onConfirm={async () => {
                        await deleteAdapter(selectedAdapter!)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger loading={loading}>Delete</Button>
                </Popconfirm>}>
                <Descriptions bordered>
                    <Descriptions.Item span={3} label="Endpoint"> {selectedAdapter?.endpoint} </Descriptions.Item>
                    <Descriptions.Item span={3} label="Type"> {selectedAdapter?.type} </Descriptions.Item>
                    <Descriptions.Item span={3} label="Username"> {selectedAdapter?.username} </Descriptions.Item>
                    <Descriptions.Item span={3} label="Password"> {selectedAdapter?.password} </Descriptions.Item>
                </Descriptions>
            </Drawer>

            <Drawer title="Add Log Adapter" width={600} placement="right" onClose={() => {
                setShowAdd(false)
            }} visible={showAdd}
                    extra={<Button loading={loading} form={"add-log-adapter"} htmlType={"submit"}>Add</Button>}>
                <Form
                    id={"add-log-adapter"}
                    name="basic"
                    labelCol={{span: 4}}
                    initialValues={{remember: true}}
                    onFinish={addAdapter}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Endpoint"
                        name="endpoint"
                        rules={[{required: true, message: 'Please input endpoint!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[{required: true, message: 'Please select type!'}]}
                    >
                        <Select
                            placeholder="Type"
                        >
                            <Select.Option value="GrayLog">GrayLog</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{required: true, message: 'Please input username!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Please input password!'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default LogAdapters;
