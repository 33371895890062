import React, {Component} from "react";
import {Button, Form, Input, Modal, notification} from "antd";
import Editor from "@monaco-editor/react";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../../Api/APIService";
import {ModelDefinitions} from "../../Interfaces/IProjectSummary";


interface Props {
    classInstance: RetterCloudObject
    modelDefinitions?: ModelDefinitions
    onSuccess?: ()=>Promise<any>
}

interface State {
    visible: boolean
    modelObject: string
    modelName?: string
    loading: boolean
    registerCompletionItemProvider?: any
}

export class CreateModelModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            modelObject: '{}',
            loading: false
        }
        this.createModel = this.createModel.bind(this)
    }

    async createModel() {
        if (!this.state.modelObject) return false
        this.setState({loading: true})
        try {
            await this.props.classInstance.call<any>({
                method: RootProjectClassMethods.upsertModel,
                body: {
                    modelName: this.state.modelName,
                    modelDefinition: JSON.parse(this.state.modelObject)
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
            this.setState({
                visible: false
            })
            if(this.props.onSuccess){
                await this.props.onSuccess()
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        this.setState({loading: false})
    }

    onCancel = () => {
        this.setState({visible: false})
    }
    render() {
        return (
            <>
                <Button type="link" onClick={() => {
                    this.setState({visible: true})
                }}>
                    Create Model
                </Button>
                <Modal title="Create Model" visible={this.state.visible} okText={'Create'}
                       width={800}
                       confirmLoading={this.state.loading}
                       onOk={this.createModel}
                       onCancel={this.onCancel}>
                    <Form
                        name={'update_model'}
                        layout="vertical"
                        initialValues={{model: this.state.modelObject}}
                        requiredMark={true}
                    >
                        <Form.Item
                            label="Model Name"
                            name="modelName"
                        >
                            <Input onChange={(e) => {
                                this.setState({modelName: e.target.value})
                            }} placeholder={'Model Name'}/>
                        </Form.Item>
                        <Form.Item
                            label="Model"
                            name="model"
                        >
                            <Editor
                                height="40vh"
                                language={"json"}
                                onChange={(value => {
                                    this.setState({modelObject: value || ''})
                                })}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}
