import React, {Component} from "react";
import {Tabs} from "antd";
import ConfigLayout from "./ConfigLayout";
import DangerZone from "./DangerZone";
import SecretsLayout from "./SecretsLayout";
import EnvsLayout from "./EnvsLayout";
import LogAdapters from "./LogAdapters";
import DestinationAdapters from "./DestinationAdapters";
import TracingAdapters from "./TracingAdapters";

interface Props {
}

interface State {

}

export class SettingsLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <Tabs destroyInactiveTabPane>
                    <Tabs.TabPane tab="Config" key="1">
                        <ConfigLayout/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Log Adapters" key="Log_Adapters">
                        <LogAdapters/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Destination Adapters" key="Destination_Adapters">
                        <DestinationAdapters/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Tracing Adapters" key="Tracing_Adapters">
                        <TracingAdapters/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Secrets" key="2">
                        <SecretsLayout/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Environments" key="3">
                        <EnvsLayout/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Danger Zone" key="4">
                        <DangerZone/>
                    </Tabs.TabPane>
                    {
                        /*
                        <Tabs.TabPane tab="Rate Limit Options" key="2">
                        <RateLimitConfigLayout/>
                    </Tabs.TabPane>
                         */
                    }
                </Tabs>
            </>
        );
    }

}
