// this file is auto generated, do not edit it directly
module.exports = {
  "rootProjectId": "root",
  "stage": "PROD",
  "captchaKey": "-",
  "region": "eu-west-1",
  "cosUrl": "api.a101data.retter.io",
  "version": "1.2.0",
  "appUrl": "https://a101data.c.retter.io/",
  "uiBucketName": "a101data.c.retter.io",
  "uiBucketDomainName": "a101data.c.retter.io"
}