import {Admin, IProjectSummary, ProjectConfig, ProjectConfigData} from "../Interfaces/IProjectSummary";
import {
    ActionTypes,
    developerServiceStore,
    projectAttachedServiceRolesStore,
    projectRolesStore,
    projectStore
} from "../Actions/Actions"
import {LiveService, Service} from "../Interfaces/Service";
import {notification} from "antd";
import {AttachedServiceRoleItem, AttachedServiceRolesResponse, IRoles, RoleItem} from "../Interfaces/IRoles";
import {IBillingAccountInfo, IBillingAccountItem} from "../Interfaces/BillingAccountInterfaces";
import {ProjectTotalCount} from "../Interfaces/BillingAccountProjectCount";
import RBS, {RetterCallResponse, RetterCloudObject, RetterCloudObjectConfig, RetterCloudObjectState} from "@retter/sdk";
import {ITemplateItem} from "../Interfaces/ITemplates";
import {IProjectEvent} from "../Interfaces/IProjectEvents";
import {IProjectAccessDefinitionsModel, IProjectDefinitionItem} from "../Interfaces/IDefinition";
import {ISubscription} from "../Interfaces/ISubscription";
import {RateLimitModel} from "../Interfaces/IRateLimitOption";
import {IDocsDetail, IGetActionSchemaListResponse} from "../Interfaces/IDocsDetail";
import {gunzipSync} from "zlib";
import {ILogsTableDetail} from "../Interfaces/ILogsTableDetail";
import {ICloudObjectClass, ICloudObjectClassFile, ISaveCloudObjectFileModel} from "../Interfaces/CloudObjetsInterfaces";
import {CoDBDocument, CoDBDocumentSummary} from "../Interfaces/CoDBInterfaces";
import axios from "axios"
import {IClassLayer, IClassLayerResponse} from "../Interfaces/LayerInterfaces";
import {IAdminSecrets} from "../Interfaces/IAdminSecrets";
import {CosLogResponse, LookupKeysResponse, SortSetListResponse} from "../Interfaces/CosInterfaces";

export enum RootProjectClassEnums {
    User = 'User',
    Project = 'Project',
    ProjectUser = 'ProjectUser',
    EmailAuthenticator = 'EmailAuthenticator',
    RetterClass = 'RetterClass',
    Invitation = 'Invitation',
    SubscriptionManager = 'SubscriptionManager',
}

export enum RootProjectClassMethods {
    sendEmailOtp = 'sendEmailOtp',
    validateEmailOtp = 'validateEmailOtp',
    createClass = 'createClass',
    removeMember = 'removeMember',
    addMember = 'addMember',
    updateRoles = 'updateRoles',
    getClassFiles = 'getFiles',
    saveClassFiles = 'save',
    deployClass = 'deploy',
    removeClass = 'removeClass',
    getClassInstances = 'getInstances',
    deleteClassInstances = 'deleteInstances',
    getAccessTokenForUser = 'getAccessTokenForUser',
    getLookupKeys = 'getInstanceLookupKeys',
    listSortedSetItems = 'listSortedSetItems',
    leaveProject = 'leaveProject',
    updateMember = 'updateMember',
    setEnvironmentVariables = 'setEnvironmentVariables',
    resetSecrets = 'resetSecrets',
    getLogsTableDetails = 'getLogsTableDetails',
    getLogs = 'getLogs',
    getLogDetails = 'getLogDetails',
    updateAlias = 'updateAlias',
    upsertModel = 'upsertModel',
    generateHelperFile = 'generateHelper',
    getClassLogsByRequestId = 'getClassLogsByRequestId',
    resetCredentials = 'resetCredentials',
    inviteUser = 'invite',
    deleteInvite = 'deleteInvitation',
    confirmInvitation = 'confirmInvitation',
    invitationConfirm = 'invitationConfirm',
    getMemoryKey = 'getMemoryKey',
    setMemoryKey = 'setMemoryKey',
    getFileList = 'getFileList',
    getFileContent = 'getFileContent',
    updateLogAdaptors = 'updateLogAdaptors',
    updateStateStreamTargets = 'updateStateStreamTargets',
    addScanJob = 'addScanJob',
    stopScanJob = 'stopScanJob',
    updateConfig = 'updateConfig',
    listSubscriptions = 'list',
    getModelDefinitions = 'getModelDefinitions',
    updateTracingAdaptors = 'updateTracingAdaptors'
}


export enum ConsoleProjectActions {
    DESTROY_PROJECT = 'rbs.console.request.DESTROY_PROJECT',
    GET_PROJECT_CONFIG = 'rbs.console.get.PROJECT_CONFIG',
    UPDATE_PROJECT_CONFIG = 'rbs.console.request.UPDATE_PROJECT_CONFIG',
    PROJECT = 'rbs.console.get.PROJECT',
    LIST_PROJECT_SERVICES = 'rbs.console.get.LIST_PROJECT_SERVICES',
    LIST_PROJECT_MEMBERS = 'rbs.console.request.LIST_PROJECT_MEMBERS',
    ADD_PROJECT_MEMBER = 'rbs.console.request.ADD_PROJECT_MEMBER',
    REMOVE_PROJECT_MEMBER = 'rbs.console.request.REMOVE_PROJECT_MEMBER',
    UPDATE_PROJECT_MEMBER_ROLES = 'rbs.console.request.UPDATE_PROJECT_MEMBER_ROLES',
    UPDATE_ATTACHED_SERVICE_ROLES = 'rbs.console.request.UPDATE_ATTACHED_SERVICE_ROLES',
    LIST_ATTACHED_SERVICE_ROLES = 'rbs.console.get.LIST_ATTACHED_SERVICE_ROLES',
    DEPLOY_TEMPLATE = 'rbs.console.request.DEPLOY_TEMPLATE',
    DEPLOY_CURRENT_TEMPLATE = 'rbs.console.request.DEPLOY_CURRENT_TEMPLATE',
    LIST_PROJECT_EVENTS = 'rbs.console.get.LIST_PROJECT_EVENTS',
    PUT_CANCEL_EVENT_TO_PROJECT_EVENTS = 'rbs.console.request.PUT_CANCEL_EVENT_TO_PROJECT_EVENTS',
    CREATE_ACCESS_DEFINITIONS = 'rbs.console.request.CREATE_ACCESS_DEFINITIONS',
    UPDATE_ACCESS_DEFINITIONS = 'rbs.console.request.UPDATE_ACCESS_DEFINITIONS',
    LIST_ACCESS_DEFINITIONS = 'rbs.console.get.LIST_ACCESS_DEFINITIONS',
    CURRENT_TEMPLATE = 'rbs.console.get.CURRENT_TEMPLATE',
    PROJECT_PATH_SUBSCRIPTIONS = 'rbs.console.get.PROJECT_PATH_SUBSCRIPTIONS',
    LIST_PROJECT_RATE_LIMITS = 'rbs.console.get.LIST_PROJECT_RATE_LIMITS',
    SAVE_PROJECT_RATE_LIMITS = 'rbs.console.request.SAVE_PROJECT_RATE_LIMITS',
    DOCS_DETAIL = 'rbs.console.get.DOCS_DETAIL',
    GET_ACTION_SCHEMA_LIST = 'rbs.console.get.GET_ACTION_SCHEMA_LIST',
    PROJECT_README = 'rbs.console.get.PROJECT_README',
    QUERY_LOGS = 'rbs.console.get.QUERY_LOGS',
    GET_LOGS_TABLE_DETAILS = 'rbs.console.get.GET_LOGS_TABLE_DETAILS',
    GET_LOG_DETAIL = 'rbs.console.get.GET_LOG_DETAIL',
    INSTALL_SERVICE_TO_PROJECT = 'rbs.console.request.INSTALL_SERVICE_TO_PROJECT',
    UNINSTALL_SERVICE_FROM_PROJECT = 'rbs.console.request.UNINSTALL_SERVICE_FROM_PROJECT',
    GET_PROJECT_ROLES = 'rbs.console.get.GET_PROJECT_ROLES',
    UPDATE_PROJECT_ROLES = 'rbs.console.request.UPDATE_PROJECT_ROLES',
}

export enum ConsoleServiceActions {
    ADD_SERVICE_MEMBER = 'rbs.console.request.ADD_SERVICE_MEMBER',
    UPDATE_SERVICE_MEMBER_ROLES = 'rbs.console.request.UPDATE_SERVICE_MEMBER_ROLES',
    SERVICE = 'rbs.console.get.SERVICE',
    UPDATE_SERVICE = 'rbs.console.request.UPDATE_SERVICE',
    DELETE_PENDING_SERVICE = 'rbs.console.request.DELETE_PENDING_SERVICE',
    REMOVE_SERVICE_MEMBER = 'rbs.console.request.REMOVE_SERVICE_MEMBER',
}

export enum ConsoleBillingAccountActions {
    BILLING_REPORT = 'rbs.console.get.BILLING_REPORT',
    BILLING_ACCOUNT_BY_DEVELOPER_ID = 'rbs.console.get.BILLING_ACCOUNT_BY_DEVELOPER_ID',
}

export enum ConsoleGlobalActions {
    CREATE_PROJECT = 'rbs.console.request.CREATE_PROJECT',
    LIST_PROJECTS = 'rbs.console.get.LIST_PROJECTS',
    SERVICE_ON_THE_STORE = 'rbs.console.get.SERVICE_ON_THE_STORE',
    LIST_SERVICES_ON_THE_STORE = 'rbs.console.get.LIST_SERVICES_ON_THE_STORE',
    TEMPLATES = 'rbs.console.get.TEMPLATES',
    LIST_BILLING_ACCOUNTS_BY_DEVELOPER_ID = 'rbs.console.get.LIST_BILLING_ACCOUNTS_BY_DEVELOPER_ID',
    CREATE_NEW_BILLING_ACCOUNT = 'rbs.console.request.CREATE_NEW_BILLING_ACCOUNT',
    CREATE_SERVICE = 'rbs.console.request.CREATE_SERVICE',
    LIST_SERVICES_BY_DEVELOPER = 'rbs.console.get.LIST_SERVICES_BY_DEVELOPER',
    GET_SUPPORTED_ACTIONS = 'rbs.console.get.GET_SUPPORTED_ACTIONS',
    GENERATE_CUSTOM_TOKEN = 'rbs.console.request.GENERATE_CUSTOM_TOKEN',
    LIST_OWN_PROJECTS = 'rbs.console.request.LIST_OWN_PROJECTS',
    LEAVE_THE_PROJECT = "rbs.core.request.LEAVE_THE_PROJECT",
    REGENERATE_ADMIN_SECRET_KEY = "rbs.core.request.REGENERATE_ADMIN_SECRET_KEY",
    GET_ADMIN_SECRETS = "rbs.core.request.GET_ADMIN_SECRETS",
}

export enum CoreActions {
    //CoDB
    PUT_DOCUMENT = "rbs.core.request.PUT_DOCUMENT",
    REQUEST_DOCUMENT = "rbs.core.request.DOCUMENT",
    REQUEST_CONSISTENT_DOCUMENT = "rbs.core.request.CONSISTENT_DOCUMENT",
    GET_DOCUMENT = "rbs.core.get.DOCUMENT",
    LIST_DOCUMENTS = "rbs.core.request.LIST_DOCUMENTS",
    LIST_COLLECTIONS = "rbs.core.request.LIST_COLLECTIONS",
    DELETE_DOCUMENT = "rbs.core.request.DELETE_DOCUMENT",
    DELETE_COLLECTION = "rbs.core.request.DELETE_COLLECTION",
    GENERATE_USER_ACCESS_TOKEN = "rbs.core.request.GENERATE_USER_ACCESS_TOKEN",

    //COS-CONSOLE
    CORE_COS_CREATE_CLASS = "rbs.core.request.CREATE_CLASS",
    CORE_COS_SAVE_FILES = "rbs.core.request.SAVE_FILES",
    CORE_COS_GET_CLASS_LIST = "rbs.core.request.GET_CLASS_LIST",
    CORE_COS_GET_CLASS_FILE_LIST = "rbs.core.request.GET_CLASS_FILE_LIST",
    CORE_COS_DEPLOY_CLASS = "rbs.core.request.DEPLOY_CLASS",
    CORE_COS_INSTANCE = "rbs.core.request.INSTANCE",
    CORE_COS_CALL = "rbs.core.request.CALL",
    CORE_COS_STATE = "rbs.core.request.STATE",
    CORE_COS_GET_CLASS_TEMPLATE_LIST = "rbs.core.request.GET_CLASS_TEMPLATE_LIST",
    UPSERT_CLASS_LAYER = "rbs.core.request.UPSERT_CLASS_LAYER",
    GET_CLASS_LAYERS = "rbs.core.request.GET_CLASS_LAYERS",
    CORE_COS_CLASS_INSTANCES = "rbs.core.request.CLASS_INSTANCES",
    CORE_COS_REQUEST_LOGS = "rbs.core.request.REQUEST_LOGS",
    DELETE_CLASS_LAYER = "rbs.core.request.DELETE_CLASS_LAYER",
    CORE_COS_REQUEST_LOOKUP_KEYS = "rbs.core.request.REQUEST_LOOKUP_KEYS",
    CORE_COS_REQUEST_SORTED_SET_ITEMS = "rbs.core.request.REQUEST_SORTED_SET_ITEMS",
    CORE_COS_DELETE_INSTANCE = "rbs.core.request.DELETE_INSTANCE",
    CORE_COS_DELETE_CLASS = "rbs.core.request.DELETE_CLASS",
}

interface RbsInstanceForBasicUsage {
    projectId: string,
    sdk: RBS
}

export interface APIService {

    rootRbsSdk: RBS

    currentRbsInstance?: RbsInstanceForBasicUsage

    rbsInstances: RbsInstanceForBasicUsage[]

    getProjects(): Promise<IProjectSummary[]>;

    getProjectSummary(projectId: string): Promise<IProjectSummary | undefined>;

    createRbsProject(alias: string): Promise<IProjectSummary | undefined>

    updateProjectConfig(projectId: string, projectConfig: ProjectConfigData, updateToken: string): Promise<ProjectConfig | undefined>

    addMemberToProject(projectId: string, email: string, roles: string[]): Promise<void>

    addMemberToService(serviceId: string, email: string, roles: string[]): Promise<void>

    createService(manifest: any): Promise<void>

    getServices(): Promise<void>

    updateService(isPublic: boolean, manifest: any, serviceId: string, updateToken: string): Promise<void>

    deletePendingService(serviceId: string): Promise<void>

    getServicesOnTheStore(): Promise<Service[]>

    getServiceDetail(serviceId: string): Promise<Service>

    getServiceDetailOnTheStore(serviceId: string): Promise<LiveService>

    installService(serviceId: string, projectId: string, serviceParameters: any): Promise<boolean>

    getProjectServices(projectId: string): Promise<{ services: LiveService[] }>

    uninstallService(serviceId: string, projectId: string): Promise<boolean>

    getProjectRoles(projectId: string): Promise<IRoles>

    updateProjectRoles(projectId: string, userRoles: RoleItem[], updateToken: string): Promise<IRoles>

    deleteProject(projectId: string): Promise<boolean>

    getBillingAccounts(): Promise<IBillingAccountItem[] | undefined>

    getBillingAccountInfo(billingAccountId: string): Promise<IBillingAccountInfo | undefined>

    createNewBillingAccount(): Promise<boolean>

    getBillingAccountProjectsCounts(billingAccountId: string, date: Date): Promise<ProjectTotalCount[] | undefined>

    getTemplates(): Promise<ITemplateItem[] | undefined>

    removeAdminFromProject(projectId: string, adminId: string): Promise<boolean>

    removeAdminFromService(serviceId: string, memberId: string): Promise<boolean>

    getProjectAttachedServiceRoles(projectId: string): Promise<AttachedServiceRolesResponse>

    updateProjectAttachedServiceRoles(projectId: string, attachedServiceRoles: AttachedServiceRoleItem[], updateToken: string): Promise<AttachedServiceRolesResponse>

    getProjectEvents(projectId: string): Promise<IProjectEvent[]>

    deployProjectTemplate(projectId: string, templateStringBase64: string): Promise<boolean>

    putCancelEvent(projectId: string): Promise<boolean>

    createProjectAccessesDefinitions(projectId: string, definitions: IProjectDefinitionItem[]): Promise<IProjectAccessDefinitionsModel | undefined>

    updateProjectAccessesDefinitions(projectId: string, definitions: IProjectDefinitionItem[], updateToken: string): Promise<IProjectAccessDefinitionsModel | undefined>

    getProjectAccessesDefinitions(projectId: string): Promise<IProjectAccessDefinitionsModel | undefined>

    deployWithCurrentTemplate(projectId: string): Promise<boolean>

    getCurrentTemplate(projectId: string): Promise<{ templateBase64String: string } | undefined>

    getProjectSubscriptions(projectId: string): Promise<ISubscription[]>

    saveProjectRateLimits(projectId: string, rateLimitModel: RateLimitModel): Promise<RateLimitModel | undefined>

    getProjectRateLimits(projectId: string): Promise<RateLimitModel | undefined>

    getDocDetails(projectId: string): Promise<IDocsDetail | undefined>

    getDocActionSchemaList(projectId: string, action: string): Promise<IGetActionSchemaListResponse | undefined>

    getSupportedActionList(): Promise<SupportedActionList>

    getProjectReadme(projectId: string): Promise<string | undefined>

    getQueryResults(projectId: string, filters?: object): Promise<any[]>

    getLogsTableDetails(projectId: string): Promise<ILogsTableDetail>

    getLogDetail(projectId: string, input: any): Promise<any>

    generateCustomTokenForDeveloperUser(projectId: string): Promise<{ customToken: string }>

    updateProjectMemberRoles(projectId: string, memberId: string, roles: string[], updateToken: string): Promise<AdminProjectSummary | undefined>

    updateServiceMemberRoles(serviceId: string, memberId: string, roles: string[], updateToken: string): Promise<DeveloperServiceSummary | undefined>

    getProjectConfig(projectId: string): Promise<ProjectConfig | undefined>

    listProjectMembers(projectId: string): Promise<Admin[]>

    listOwnProjects(): Promise<{ adminId: string, projectId: string }[]>

    putDocument(projectId: string, payload: { path: string, data: any, ifNotExist: boolean }): Promise<CoDBDocumentSummary | undefined>

    requestDocument(projectId: string, path: string): Promise<CoDBDocument | undefined>

    listCollections(projectId: string, path: string): Promise<{ data: string[] } | undefined>

    listDocuments(projectId: string, path: string): Promise<{
        data: string[] // document paths
        _paginationToken: string | null
    } | undefined>

    deleteDocument(projectId: string, path: string): Promise<boolean>

    deleteCollection(projectId: string, path: string): Promise<boolean>

    getCOSClassList(projectId: string): Promise<ICloudObjectClass[]>

    createCOSClass(projectId: string, classId: string, templateName: string): Promise<ICloudObjectClass | undefined>

    saveCOSClassFiles(projectId: string, classId: string, files: ISaveCloudObjectFileModel[]): Promise<ICloudObjectClassFile[]>

    getClassFileList(projectId: string, classId: string): Promise<ICloudObjectClassFile[]>

    deployClass(projectId: string, classId: string): Promise<boolean>

    getCosInstance(projectId: string, classId: string, input: { _preventNotFound: boolean, instanceId?: string, key?: { name: string, value: string } }, payload?: any): Promise<RetterCloudObject | undefined>

    callCosInstance(projectId: string, input: { classId: string, instanceId?: string, method: string, payload: any },
                    user?: { identity: string, userId: string }): Promise<RetterCallResponse<any> | undefined>

    getClassState(projectId: string, input: { classId: string; instanceId: string },
                  user?: { identity: string, userId: string }): Promise<RetterCallResponse<RetterCloudObjectState> | undefined>

    generateUserAccessToken(projectId: string, input: { userId: string, identity: string }): Promise<string>

    leaveTheProject(projectId: string): Promise<boolean>

    getClassTemplateList(projectId: string): Promise<ClassTemplateListItem[] | undefined>

    upsertClassLayer(projectId: string, layer: IClassLayer): Promise<IClassLayerResponse | undefined>

    getClassLayers(projectId: string): Promise<IClassLayerResponse[]>

    searchNpmPackage(text: string): Promise<any[]>

    getNpmPackageDetail(packageName: string): Promise<any | undefined>

    getAdminSecrets(): Promise<IAdminSecrets | undefined>

    regenerateAdminSecretKey(): Promise<boolean>

    getClassInstanceList(projectId: string, classId: string): Promise<string[]>

    getCosRequestLogs(projectId: string, requestId: string): Promise<CosLogResponse>

    searchNPMPackages(query: string, limit?: number): Promise<any[]>

    deleteClassLayer(projectId: string, layerName: string): Promise<boolean>

    getLookupKeys(projectId: string, classId: string, instanceId: string, nextToken?: string): Promise<LookupKeysResponse>

    getSortedListItems(projectId: string, classId: string, instanceId: string, nextToken?: string): Promise<SortSetListResponse>

    deleteCosClass(projectId: string, classId: string): Promise<boolean>

    deleteCosClassInstance(projectId: string, classId: string, instanceId: string): Promise<boolean>
}

export interface ClassTemplateListItem {
    title: string,
    name: string
}


export interface AdminProjectSummary {
    roles: string[]
    adminProjectId: string
}

interface DeveloperServiceSummary {
    serviceId: string;
    developerId: string;
    roles: string[];
    email: string
}


export interface SupportedActionList {
    projectActions: string[]
    serviceActions: string[]
    globalActions: string[]
    coreServiceActions: string[]
}

interface BaseRequestObject {
    payload?: any
}

/**
 * As the name suggests, handles API calls to the Pet service.
 */
export class HttpAPIService implements APIService {
    classInstanceList: RetterCloudObject[] = []

    rbsInstances: RbsInstanceForBasicUsage[] = []

    rootRbsSdk: RBS

    constructor(sdk: RBS) {
        this.rootRbsSdk = sdk
    }

    private async getRequestContext(action: string | undefined) {
        if (!action)
            throw new Error('Action is undefined')
        const actionType = action.split('.')[2]
        return {
            actionType,
            action
        }
    }

    private async getCloudObject(props: { projectId: string, cos: RetterCloudObjectConfig }, createNew = false): Promise<any> {
    }

    private async doRequestToCore(props: {
        project?: { action: ConsoleProjectActions | CoreActions, projectId: string } & BaseRequestObject,
        service?: { action: ConsoleServiceActions, serviceId: string } & BaseRequestObject,
        billing?: { action: ConsoleBillingAccountActions, billingAccountId: string } & BaseRequestObject,
        global?: { action: ConsoleGlobalActions } & BaseRequestObject
    }, rawResponse?: boolean): Promise<any> {
    }

    public async getProjects() {
        const response = await this.doRequestToCore({
            global: {action: ConsoleGlobalActions.LIST_PROJECTS}
        })
        response.sort(function (a: any, b: any) {
            if (a.alias.toLowerCase() < b.alias.toLowerCase()) {
                return -1;
            }
            if (a.alias.toLowerCase() > b.alias.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return response;
    }

    public async createRbsProject(alias: string): Promise<IProjectSummary | undefined> {
        try {
            projectStore.dispatch({type: ActionTypes.PROJECT.types.CREATING})
            const response = await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.CREATE_PROJECT, payload: {alias}}
            })
            projectStore.dispatch({type: ActionTypes.PROJECT.types.CREATED, data: response})
            return <IProjectSummary>response;
        } catch (e) {
            let data: any = {}
            if (e.response && e.response.data) {
                data = e.response.data
            }
            projectStore.dispatch({type: ActionTypes.PROJECT.types.ERROR, data})
        }
    }

    public async updateProjectConfig(projectId: string, projectConfig: ProjectConfigData, updateToken: string): Promise<ProjectConfig | undefined> {
        try {
            projectStore.dispatch({type: ActionTypes.PROJECT.types.CONFIG_UPDATING})
            const response = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UPDATE_PROJECT_CONFIG, projectId, payload: {
                        config: JSON.parse(JSON.stringify(projectConfig)),
                        updateToken
                    }
                },

            })
            projectStore.dispatch({type: ActionTypes.PROJECT.types.CONFIG_UPDATED, data: response})
            return <ProjectConfig>response;
        } catch (e) {
            console.log(e)
            let data: any = {}
            if (e.response && e.response.data) {
                data = e.response.data
            }
            projectStore.dispatch({type: ActionTypes.PROJECT.types.ERROR, data})
        }
    }

    async getProjectSummary(projectId: string): Promise<IProjectSummary | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.PROJECT, projectId},

            })
        } catch (e) {
            return undefined
        }
    }

    async getProjectServices(projectId: string): Promise<{ services: LiveService[] }> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_PROJECT_SERVICES, projectId},

            })
            return <{ services: LiveService[] }>response
        } catch (e) {
            return {services: []}
        }
    }

    async addMemberToProject(projectId: string, email: string, roles: string[]): Promise<void> {
        try {
            await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.ADD_PROJECT_MEMBER, projectId, payload: {
                        email, roles
                    }
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Member',
                description: 'Member successfully added',
            })
        } catch (e) {
        }
    }

    async createService(manifest: any) {
        try {
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.CREATING})
            await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.CREATE_SERVICE, payload: {manifest}}
            })
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.CREATED})
        } catch (e) {
            let data: any = {}
            if (e.response && e.response.data) {
                data = e.response.data
            }
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.CREATE_ERROR, data})
        }
    }

    async getServices() {
        try {
            const response = await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.LIST_SERVICES_BY_DEVELOPER}
            })
            response.sort(function (a: any, b: any) {
                if (a.manifest.name.toLowerCase() < b.manifest.name.toLowerCase()) {
                    return -1;
                }
                if (a.manifest.name.toLowerCase() > b.manifest.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            return response
        } catch (e) {
        }
    }

    async getServiceDetail(serviceId: string) {
        try {
            const resp = await this.doRequestToCore({
                service: {action: ConsoleServiceActions.SERVICE, serviceId}
            })
            if (resp && resp.live && resp.live.manifest && !resp.live.manifest.actions) {
                resp.live.manifest["actions"] = {
                    receives: [],
                    sends: []
                }
            }
            return resp
        } catch (e) {
        }
    }

    async getServicesOnTheStore() {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.LIST_SERVICES_ON_THE_STORE}
            })
        } catch (e) {
        }
    }

    async updateService(isPublic: boolean, manifest: any, serviceId: string, updateToken: string): Promise<void> {
        try {
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.UPDATING})
            await this.doRequestToCore({
                service: {
                    action: ConsoleServiceActions.UPDATE_SERVICE,
                    serviceId,
                    payload: {manifest, isPublic, updateToken}
                }
            })
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.UPDATED})
        } catch (e) {
            let data: any = {}
            if (e.response && e.response.data) {
                data = e.response.data
            }
            developerServiceStore.dispatch({type: ActionTypes.DEVELOPER_SERVICE.types.ERROR, data})
        }
    }

    async deletePendingService(serviceId: string) {
        try {
            await this.doRequestToCore({
                service: {action: ConsoleServiceActions.DELETE_PENDING_SERVICE, serviceId}
            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Pending Service',
                description: `Pending service successfully deleted`,
            })
        } catch (e) {
        }
    }

    async installService(serviceId: string, projectId: string, serviceParameters: string[]) {
        try {
            await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.INSTALL_SERVICE_TO_PROJECT,
                    projectId,
                    payload: {serviceId, serviceParameters}
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service',
                description: `Service installed successfully`,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async uninstallService(serviceId: string, projectId: string) {
        try {
            await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UNINSTALL_SERVICE_FROM_PROJECT,
                    projectId,
                    payload: {serviceId}
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service',
                description: `Service uninstalled successfully`,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getServiceDetailOnTheStore(serviceId: string) {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.SERVICE_ON_THE_STORE, payload: {serviceId}}
            })
        } catch (e) {
        }
    }

    async getProjectRoles(projectId: string) {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.GET_PROJECT_ROLES, projectId},

            })
        } catch (e) {
        }
    }

    async updateProjectRoles(projectId: string, projectRoles: RoleItem[], updateToken: string) {
        try {
            projectRolesStore.dispatch({type: ActionTypes.PROJECT_USER_ROLES.types.UPDATING})
            const response = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UPDATE_PROJECT_ROLES,
                    projectId,
                    payload: {projectRoles, updateToken}
                },

            })
            projectRolesStore.dispatch({type: ActionTypes.PROJECT_USER_ROLES.types.UPDATING})
            return response
        } catch (e) {
            return false
        }
    }

    async deleteProject(projectId: string) {
        try {
            await this.doRequestToCore({
                project: {action: ConsoleProjectActions.DESTROY_PROJECT, projectId},

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project',
                description: `Project successfully deleted`,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async addMemberToService(serviceId: string, email: string, roles: string[]): Promise<void> {
        try {
            await this.doRequestToCore({
                service: {action: ConsoleServiceActions.ADD_SERVICE_MEMBER, serviceId, payload: {email, roles}}
            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service',
                description: `Member successfully added`,
            })
        } catch (e) {
        }
    }

    async getBillingAccounts(): Promise<IBillingAccountItem[] | undefined> {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.LIST_BILLING_ACCOUNTS_BY_DEVELOPER_ID}
            })
        } catch (e) {
            return undefined
        }
    }

    async getBillingAccountInfo(billingAccountId: string): Promise<IBillingAccountInfo | undefined> {
        try {
            return await this.doRequestToCore({
                billing: {action: ConsoleBillingAccountActions.BILLING_ACCOUNT_BY_DEVELOPER_ID, billingAccountId}
            })
        } catch (e) {
            return undefined
        }

    }

    async createNewBillingAccount(): Promise<boolean> {
        try {
            await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.CREATE_NEW_BILLING_ACCOUNT}
            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Billing',
                description: `Billing account successfully created`,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getBillingAccountProjectsCounts(billingAccountId: string, date: Date): Promise<ProjectTotalCount[] | undefined> {
        try {
            return await this.doRequestToCore({
                billing: {
                    action: ConsoleBillingAccountActions.BILLING_REPORT,
                    billingAccountId,
                    payload: {date: date.toISOString()}
                }
            })
        } catch (e) {
            return undefined
        }
    }

    async getTemplates(): Promise<ITemplateItem[] | undefined> {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.TEMPLATES}
            })
        } catch (e) {
            return undefined
        }
    }

    async removeAdminFromProject(projectId: string, adminId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: ConsoleProjectActions.REMOVE_PROJECT_MEMBER, projectId, payload: {adminId}},

            })
            return true
        } catch (e) {
            return false
        }
    }

    async getProjectAttachedServiceRoles(projectId: string) {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_ATTACHED_SERVICE_ROLES, projectId},

            })
        } catch (e) {
        }
    }

    async updateProjectAttachedServiceRoles(projectId: string, attachedServiceRoles: AttachedServiceRoleItem[], updateToken: string) {
        try {
            projectAttachedServiceRolesStore.dispatch({type: ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.UPDATING})
            const response = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UPDATE_ATTACHED_SERVICE_ROLES,
                    projectId,
                    payload: {serviceRoles: attachedServiceRoles}
                },

            })

            projectAttachedServiceRolesStore.dispatch({type: ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.UPDATING})
            return response
        } catch (e) {
            projectAttachedServiceRolesStore.dispatch({type: ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.ERROR})
            return false
        }
    }

    async getProjectEvents(projectId: string): Promise<IProjectEvent[]> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_PROJECT_EVENTS, projectId},

            })
            if (!response) return []
            return <IProjectEvent[]>response
        } catch (e) {
            return []
        }
    }

    async deployProjectTemplate(projectId: string, templateStringBase64: string): Promise<boolean> {
        try {
            const response = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.DEPLOY_TEMPLATE,
                    projectId,
                    payload: {template: templateStringBase64}
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Template',
                description: response.message,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async putCancelEvent(projectId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: ConsoleProjectActions.PUT_CANCEL_EVENT_TO_PROJECT_EVENTS, projectId},

            })
            return true
        } catch (e) {
            return false
        }
    }

    async createProjectAccessesDefinitions(projectId: string, definitions: IProjectDefinitionItem[]): Promise<IProjectAccessDefinitionsModel | undefined> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.CREATE_ACCESS_DEFINITIONS, projectId, payload: {definitions}},

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Definitions',
                description: response.message || `Definitions create success`,
            })
            return <IProjectAccessDefinitionsModel>response
        } catch (e) {
            return undefined
        }
    }

    async updateProjectAccessesDefinitions(projectId: string, definitions: IProjectDefinitionItem[], updateToken: string): Promise<IProjectAccessDefinitionsModel | undefined> {
        try {
            const response = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UPDATE_ACCESS_DEFINITIONS, projectId, payload: {
                        definitions,
                        updateToken
                    }
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Definitions',
                description: response.message || `Definitions update success`,
            })
            return <IProjectAccessDefinitionsModel>response
        } catch (e) {
            return undefined
        }
    }

    async getProjectAccessesDefinitions(projectId: string): Promise<IProjectAccessDefinitionsModel | undefined> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_ACCESS_DEFINITIONS, projectId},

            })
            return <IProjectAccessDefinitionsModel>response
        } catch (e) {
            return undefined
        }
    }

    async deployWithCurrentTemplate(projectId: string): Promise<boolean> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.DEPLOY_CURRENT_TEMPLATE, projectId},

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Template',
                description: response.message,
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getCurrentTemplate(projectId: string): Promise<{ templateBase64String: string } | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.CURRENT_TEMPLATE, projectId},

            })
        } catch (e) {
            return undefined
        }
    }

    async getProjectSubscriptions(projectId: string): Promise<ISubscription[]> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.PROJECT_PATH_SUBSCRIPTIONS, projectId},

            })
            return response as ISubscription[];
        } catch (e) {
            return []
        }
    }

    async saveProjectRateLimits(projectId: string, rateLimitModel: RateLimitModel): Promise<RateLimitModel | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.SAVE_PROJECT_RATE_LIMITS, projectId, payload: {rateLimitModel}},

            })
        } catch (e) {
            return undefined
        }
    }

    async getProjectRateLimits(projectId: string): Promise<RateLimitModel | undefined> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_PROJECT_RATE_LIMITS, projectId},

            })
            return response as RateLimitModel;
        } catch (e) {
            return undefined
        }
    }

    async getDocDetails(projectId: string): Promise<IDocsDetail | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.DOCS_DETAIL, projectId},

            }) as IDocsDetail;
        } catch (e) {
            return undefined
        }
    }

    async getDocActionSchemaList(projectId: string, action: string): Promise<IGetActionSchemaListResponse | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.GET_ACTION_SCHEMA_LIST, projectId, payload: {action}},

            }) as IGetActionSchemaListResponse;
        } catch (e) {
            return undefined
        }
    }

    async getSupportedActionList(): Promise<SupportedActionList> {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.GET_SUPPORTED_ACTIONS}
            })
        } catch (e) {
            return {
                coreServiceActions: [], globalActions: [], projectActions: [], serviceActions: []
            }
        }
    }

    async getProjectReadme(projectId: string): Promise<string | undefined> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.PROJECT_README, projectId},

            })
            return response.readme as string
        } catch (e) {
            return undefined
        }
    }

    async getQueryResults(projectId: string, filters?: object): Promise<any[]> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.QUERY_LOGS, projectId, payload: filters || {}},

            })
            if (!response.result)
                return []
            return JSON.parse(gunzipSync(Buffer.from(response.result, 'base64')).toString('utf-8'))
        } catch (e) {
            return []
        }
    }

    async getLogsTableDetails(projectId: string): Promise<ILogsTableDetail> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.GET_LOGS_TABLE_DETAILS, projectId},

            })

            if (!response.columns) {
                response["columns"] = []
            }

            if (!response.partitionKeys) {
                response["partitionKeys"] = []
            }

            return response
        } catch (e) {
            return {columns: [], partitionKeys: []}
        }
    }

    async getLogDetail(projectId: string, input: any): Promise<any> {
        try {
            const response = await this.doRequestToCore({
                project: {action: ConsoleProjectActions.GET_LOG_DETAIL, projectId, payload: input},

            })
            return JSON.parse(gunzipSync(Buffer.from(response.result, 'base64')).toString('utf-8'))
        } catch (e) {
            return undefined
        }
    }

    async generateCustomTokenForDeveloperUser(projectId: string): Promise<{ customToken: string }> {
        return await this.doRequestToCore({
            global: {action: ConsoleGlobalActions.GENERATE_CUSTOM_TOKEN, payload: {projectId}}
        })
    }

    async updateProjectMemberRoles(projectId: string, memberId: string, roles: string[], updateToken: string): Promise<AdminProjectSummary | undefined> {
        try {
            const resp = await this.doRequestToCore({
                project: {
                    action: ConsoleProjectActions.UPDATE_PROJECT_MEMBER_ROLES, projectId, payload: {
                        memberId, roles, updateToken
                    }
                },

            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Member',
                description: 'Member successfully updated',
            })
            return resp
        } catch (e) {
            return undefined
        }
    }

    async updateServiceMemberRoles(serviceId: string, memberId: string, roles: string[], updateToken: string): Promise<DeveloperServiceSummary | undefined> {
        try {
            const resp = await this.doRequestToCore({
                service: {
                    action: ConsoleServiceActions.UPDATE_SERVICE_MEMBER_ROLES, serviceId, payload: {
                        memberId, roles, updateToken
                    }
                }
            })
            notification["success"]({
                placement: 'bottomRight',
                message: 'Member',
                description: 'Member successfully added',
            })
            return resp
        } catch (e) {
            return undefined
        }
    }

    async removeAdminFromService(serviceId: string, memberId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                service: {action: ConsoleServiceActions.REMOVE_SERVICE_MEMBER, serviceId, payload: {memberId}},
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getProjectConfig(projectId: string): Promise<ProjectConfig | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.GET_PROJECT_CONFIG, projectId},

            })
        } catch (e) {
            return undefined
        }
    }

    async listProjectMembers(projectId: string): Promise<Admin[]> {
        try {
            return await this.doRequestToCore({
                project: {action: ConsoleProjectActions.LIST_PROJECT_MEMBERS, projectId},

            })
        } catch (e) {
            return []
        }
    }

    async listOwnProjects(): Promise<{ adminId: string, projectId: string }[]> {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.LIST_OWN_PROJECTS}
            })
        } catch (e) {
            return []
        }
    }

    async putDocument(projectId: string, payload: { path: string, data: any, ifNotExist: boolean }): Promise<CoDBDocumentSummary | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.PUT_DOCUMENT, projectId, payload},

            })
        } catch (e) {
            return undefined
        }
    }

    async requestDocument(projectId: string, path: string): Promise<CoDBDocument | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.REQUEST_DOCUMENT, projectId, payload: {path}},

            })
        } catch (e) {
            return undefined
        }
    }

    async listCollections(projectId: string, path: string): Promise<{ data: string[] } | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.LIST_COLLECTIONS, projectId, payload: {path}},

            })
        } catch (e) {
            return undefined
        }
    }

    async listDocuments(projectId: string, path: string): Promise<{
        data: string[] // document paths
        _paginationToken: string | null
    } | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.LIST_DOCUMENTS, projectId, payload: {path}},

            })
        } catch (e) {
            return undefined
        }
    }

    async deleteDocument(projectId: string, path: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.DELETE_DOCUMENT, projectId, payload: {path}}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async deleteCollection(projectId: string, path: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.DELETE_COLLECTION, projectId, payload: {collectionPath: path}},
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getCOSClassList(projectId: string): Promise<ICloudObjectClass[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_GET_CLASS_LIST, projectId}
            })
        } catch (e) {
            return []
        }
    }

    async geCOSClassList(projectId: string): Promise<ICloudObjectClass[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_GET_CLASS_LIST, projectId}
            })
        } catch (e) {
            return []
        }
    }

    async createCOSClass(projectId: string, classId: string, templateName: string): Promise<ICloudObjectClass | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_CREATE_CLASS, projectId, payload: {classId, templateName}}
            })
        } catch (e) {
            return undefined
        }
    }

    async saveCOSClassFiles(projectId: string, classId: string, files: ISaveCloudObjectFileModel[]): Promise<ICloudObjectClassFile[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_SAVE_FILES, projectId, payload: {files}}
            })
        } catch (e) {
            return []
        }
    }

    async getClassFileList(projectId: string, classId: string): Promise<ICloudObjectClassFile[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_GET_CLASS_FILE_LIST, projectId, payload: {classId}}
            })
        } catch (e) {
            return []
        }
    }

    async deployClass(projectId: string, classId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_DEPLOY_CLASS, projectId, payload: {classId}}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getCosInstance(projectId: string, classId: string, input: { _preventNotFound: boolean, instanceId?: string, key?: { name: string, value: string } }, payload?: any): Promise<RetterCloudObject | undefined> {
        try {
            return await this.getCloudObject({
                projectId,
                cos: {
                    queryStringParams: {_preventNotFound: input._preventNotFound ? 'true' : 'false'},
                    classId,
                    instanceId: input.instanceId,
                    key: input.key,
                    body: payload
                },
            }, true)
        } catch (e) {
            console.log(e)
            let data: any = {}
            let error = e.toString()
            if (e.response && e.response.data) {
                data = e.response.data
                error += '\n' + (data.message || data.error || JSON.stringify(data) || '')
            }
            notification["error"]({
                placement: 'bottomRight',
                message: `Class Error`,
                description: error
            })
            return undefined
        }
    }

    async callCosInstance(projectId: string, input: { classId: string, instanceId?: string, method: string, payload: any }
        , user?: { identity: string, userId: string }): Promise<RetterCallResponse<any> | undefined> {
        return undefined
    }

    async getClassState(projectId: string, input: { classId: string; instanceId: string },
                        user?: { identity: string, userId: string }): Promise<RetterCallResponse<RetterCloudObjectState> | undefined> {
        try {
            const cos = await this.getCloudObject({
                projectId,
                cos: input
            })

            let accessToken
            if (user)
                accessToken = await this.generateUserAccessToken(projectId, user)

            return await cos.getState({
                token: accessToken
            })
        } catch (e) {
            console.log(e)
            let data: any = {}
            let error = e.toString()
            if (e.response && e.response.data) {
                data = e.response.data
                error += '\n' + (data.message || data.error || JSON.stringify(data) || '')
            }
            notification["error"]({
                placement: 'bottomRight',
                message: `Class Error`,
                description: error
            })
            return undefined
        }
    }

    async generateUserAccessToken(projectId: string, input: { userId: string, identity: string }): Promise<string> {
        return await this.doRequestToCore({
            project: {action: CoreActions.GENERATE_USER_ACCESS_TOKEN, projectId, payload: input}
        })
    }

    async leaveTheProject(projectId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.LEAVE_THE_PROJECT, payload: {projectId}}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getClassTemplateList(projectId: string): Promise<ClassTemplateListItem[] | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_GET_CLASS_TEMPLATE_LIST, projectId}
            })
        } catch (e) {
            return undefined
        }
    }

    async upsertClassLayer(projectId: string, layer: IClassLayer): Promise<IClassLayerResponse | undefined> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.UPSERT_CLASS_LAYER, projectId, payload: {layer}}
            })
        } catch (e) {
            return undefined
        }
    }

    async getClassLayers(projectId: string): Promise<IClassLayerResponse[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.GET_CLASS_LAYERS, projectId}
            })
        } catch (e) {
            return []
        }
    }

    async searchNpmPackage(text: string): Promise<any[]> {
        try {
            const response = await axios({
                url: `https://registry.npmjs.org/-/v1/search?${text}=lodash&size=5`,
                method: 'get',
                params: {
                    text
                }
            })
            return response.data.objects
        } catch (e) {
            return []
        }
    }

    async getNpmPackageDetail(packageName: string): Promise<any | undefined> {
        try {
            if (packageName && packageName !== '') {
                const response = await axios({
                    url: `https://registry.npmjs.org/${packageName}`,
                    method: 'get'
                })
                return response.data
            }
            return undefined
        } catch (e) {
            return undefined
        }
    }

    async searchNPMPackages(query: string, limit?: number): Promise<any[]> {
        if (query.length === 0) return []
        if (!limit) limit = 10

        try {
            const response = await axios.get(`https://api.npms.io/v2/search?q=${encodeURIComponent(query)}&size=${limit}`)
            return response.data.results.map((item: any) => {
                return {
                    name: item.package.name,
                    description: item.package.description,
                    version: item.package.version
                }
            })
        } catch (e) {
        }
        return []
    }

    async getAdminSecrets(): Promise<IAdminSecrets | undefined> {
        try {
            return await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.GET_ADMIN_SECRETS}
            })
        } catch (e) {
            return undefined

        }
    }

    async regenerateAdminSecretKey(): Promise<boolean> {
        try {
            await this.doRequestToCore({
                global: {action: ConsoleGlobalActions.REGENERATE_ADMIN_SECRET_KEY}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getClassInstanceList(projectId: string, classId: string): Promise<string[]> {
        try {
            return await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_CLASS_INSTANCES, projectId, payload: {classId}}
            })
        } catch (e) {
            return []
        }
    }

    async getCosRequestLogs(projectId: string, requestId: string): Promise<CosLogResponse> {
        try {
            const res = await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_REQUEST_LOGS, projectId, payload: {requestId}}
            })
            return {
                logs: res.logs || [],
                diagram: res.diagram || []
            }
        } catch (e) {
            return {
                logs: [],
                diagram: []
            }
        }
    }

    async deleteClassLayer(projectId: string, layerName: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.DELETE_CLASS_LAYER, projectId, payload: {layerName}}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async getLookupKeys(projectId: string, classId: string, instanceId: string, nextToken?: string): Promise<LookupKeysResponse> {
        try {
            return await this.doRequestToCore({
                project: {
                    action: CoreActions.CORE_COS_REQUEST_LOOKUP_KEYS, projectId, payload: {
                        classId,
                        instanceId,
                        nextToken
                    }
                }
            })
        } catch (e) {
            return {
                lookupKeys: []
            }
        }
    }

    async getSortedListItems(projectId: string, classId: string, instanceId: string, nextToken?: string): Promise<SortSetListResponse> {
        try {
            return await this.doRequestToCore({
                project: {
                    action: CoreActions.CORE_COS_REQUEST_SORTED_SET_ITEMS, projectId, payload: {
                        classId,
                        instanceId,
                        nextToken
                    }
                }
            })
        } catch (e) {
            return {
                sortedSetItems: []
            }
        }
    }

    async deleteCosClass(projectId: string, classId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_DELETE_CLASS, projectId, payload: {classId}}
            })
            return true
        } catch (e) {
            return false
        }
    }

    async deleteCosClassInstance(projectId: string, classId: string, instanceId: string): Promise<boolean> {
        try {
            await this.doRequestToCore({
                project: {action: CoreActions.CORE_COS_DELETE_INSTANCE, projectId, payload: {classId, instanceId}}
            })
            return true
        } catch (e) {
            return false
        }
    }

}
