import React, {Component} from "react";
import {
    Button,
    Collapse,
    Descriptions,
    Divider,
    Input,
    List,
    PageHeader,
    Result,
    Skeleton,
    Space,
    Steps,
    Tabs,
    Typography
} from 'antd';
import {APIService} from "../../Api/APIService";
import {RouteComponentProps} from "react-router-dom";
import CustomSpinner from "../../Components/CustomSpinner";
import {UpdateServiceModal} from "../../Modals/UpdateServiceModal";
import {AddServiceToProjectModel} from "../../Modals/AddServiceToProjectModel";
import {ActionTypes, developerServiceStore} from "../../Actions/Actions";
import {Service} from "../../Interfaces/Service";
import {AddMemberToService} from "../../Modals/AddMemberToService";
import {LinkOutlined} from "@ant-design/icons";
import {ShowManifestAsJsonModal} from "../../Modals/ShowManifestAsJsonModal";
import DeveloperList from "./DeveloperList";

const {TextArea} = Input;
const {Step} = Steps;
const {Text} = Typography;
const {Panel} = Collapse;


const {TabPane} = Tabs

interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps<{ serviceId: string }>
}

interface State {
    service?: Service
    loading: boolean
    configStatus: string
    activeMenu: string
    pendingStateNumber: number
    pendingStepStatus: 'wait' | 'process' | 'finish' | 'error'
}

export default class ServiceOverviewContent extends Component<Props, State> {
    private readonly apiService: APIService;
    private readonly serviceId: string;
    private pendingStatusLineNumbers: any = {
        waitingForReview: 0,
        inReview: 1,
        pendingPublish: 2,
        rejected: 2,
        published: 2
    }

    constructor(props: Props) {
        super(props);

        this.serviceId = props.routeComponentProps.match.params.serviceId

        this.apiService = props.apiService


        this.state = {
            activeMenu: "projectOverview",
            service: undefined,
            loading: true,
            configStatus: "",
            pendingStateNumber: 0,
            pendingStepStatus: "process"
        }
        this.servicePublish = this.servicePublish.bind(this)
        this.deletePendingService = this.deletePendingService.bind(this)
        this.successCallback = this.successCallback.bind(this)
    }

    async getServiceDetail() {
        this.setState({loading: true})
        const response = await this.apiService.getServiceDetail(this.serviceId)
        if (response) {
            this.setState({service: response, loading: false})
            if (response.pending) {
                if (response.pending.status === "rejected") {
                    this.setState({pendingStepStatus: "error"})
                } else if (response.pending.status === "published") {
                    this.setState({pendingStepStatus: "finish"})
                }
                this.setState({pendingStateNumber: this.pendingStatusLineNumbers[response.pending.status]})
            }
        }
    }

    async componentDidMount() {
        await this.getServiceDetail()
        developerServiceStore.subscribe(() => {
            if (developerServiceStore.getState().state === ActionTypes.DEVELOPER_SERVICE.types.UPDATED) {
                this.getServiceDetail()
            }
        })

    }

    async servicePublish() {
        this.setState({loading: true})
        const manifest = this.state.service!.pending ? this.state.service!.pending.manifest : this.state.service!.live!.manifest
        await this.apiService.updateService(true, manifest, manifest.id, this.state.service?.live!.updateToken!)
        this.setState({loading: false})
        await this.getServiceDetail()
    }

    async deletePendingService() {
        this.setState({loading: true})
        await this.apiService.deletePendingService(this.state.service!.live!.manifest.id)
        this.setState({loading: false})
        await this.getServiceDetail()
    }

    async successCallback() {
        await this.getServiceDetail()
    }

    render() {
        return (
            <CustomSpinner spinning={this.state.loading}>
                {
                    this.state.service ? <div className="site-page-header-ghost-wrapper">

                        <PageHeader
                            ghost={false}
                            onBack={() => window.history.back()}
                            title={this.state.service!.live!.manifest.name}
                            subTitle="Service Overview"
                            extra={[
                                <AddMemberToService serviceId={this.serviceId}
                                                    successCallback={this.successCallback}
                                                    apiService={this.apiService}/>,
                                <AddServiceToProjectModel serviceId={this.state.service!.live!.manifest.id}
                                                          apiService={this.apiService}
                                                          service={this.state.service}/>,
                                <>
                                    {this.state.service.pending ? "" : <>
                                        {!this.state.service!.live!.public ? <Button onClick={this.servicePublish}
                                                                                     type={"primary"}>Publish</Button> : ""}
                                        <UpdateServiceModal isPublic={this.state.service!.live!.public}
                                                            liveService={this.state.service.live!}
                                                            apiService={this.apiService}/>
                                    </>}
                                </>
                            ]}
                            footer={
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="Service Manifest" key="1">
                                        <Divider/>
                                        {
                                            this.state.service.live ? <div className="site-page-header-ghost-wrapper">
                                                <ShowManifestAsJsonModal manifest={this.state.service.live.manifest}/>
                                                <Divider/>
                                                <b>Handler:</b><Button target={"_blank"} type="link"
                                                                       icon={<LinkOutlined/>}
                                                                       href={this.state.service.live.manifest.handler}>{this.state.service.live.manifest.handler}</Button>
                                                <br/>
                                                <b>Event Handler:</b><Button target={"_blank"} type="link"
                                                                             icon={<LinkOutlined/>}
                                                                             href={this.state.service.live.manifest.eventHandler}>{this.state.service.live.manifest.eventHandler}</Button>
                                                <Divider/>
                                                <b>Description</b><br/>
                                                {this.state.service.live.manifest.description}
                                                <Divider/>
                                                <Collapse defaultActiveKey={['1', '2']}>
                                                    <Panel header="Permissions" key="1">
                                                        <Divider orientation="left">Receives</Divider>
                                                        <List
                                                            size="small"
                                                            bordered
                                                            dataSource={this.state.service.live.manifest.actions.receives}
                                                            renderItem={item => <List.Item>{item}</List.Item>}
                                                        />
                                                        <Divider orientation="left">Sends</Divider>
                                                        <List
                                                            size="small"
                                                            bordered
                                                            dataSource={this.state.service.live.manifest.actions.sends}
                                                            renderItem={item => <List.Item>{item}</List.Item>}
                                                        />
                                                    </Panel>
                                                </Collapse>
                                                <Divider/>
                                                <List
                                                    header={<b>Depends On</b>}
                                                    bordered
                                                    dataSource={this.state.service.live.manifest.dependsOn}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            {item}
                                                        </List.Item>
                                                    )}
                                                />
                                                <Divider/>
                                            </div> : <Skeleton loading={this.state.loading}/>
                                        }
                                    </TabPane>
                                    <TabPane tab="Members" key="2">
                                        <Divider/>
                                        <DeveloperList apiService={this.apiService} serviceId={this.serviceId}/>
                                    </TabPane>
                                    {this.state.service.pending ? <TabPane tab="Publish Status" key="3">
                                        <Divider/>
                                        {
                                            this.state.service.pending ? <>
                                                <Button onClick={this.deletePendingService} type={"primary"}
                                                        danger={true}>Delete</Button>
                                                <Divider/>
                                                {this.state.pendingStepStatus === "finish" ? <>
                                                    <Result
                                                        status="success"
                                                        title="Successfully Published!"
                                                        subTitle=""
                                                        extra={[]}
                                                    />
                                                </> : <>
                                                    <Steps direction="vertical" size="small"
                                                           current={this.state.pendingStateNumber}
                                                           status={this.state.pendingStepStatus}>
                                                        <Step title="Waiting For Review" description=""/>
                                                        <Step title="In Review" description=""/>
                                                        {this.state.service.pending.status === "rejected" ?
                                                            <></> :
                                                            <Step title="Pending Publish" description=""/>}
                                                        {this.state.service.pending.status === "rejected" ?
                                                            <Step title="Rejected" description=""/> :
                                                            <Step title="Published" description=""/>}
                                                    </Steps>
                                                </>}
                                                <TextArea rows={16}
                                                          defaultValue={JSON.stringify(this.state.service.pending.manifest, null, 4) || "[]"}/>
                                            </> : "Not yet published"
                                        }
                                    </TabPane> : ""}
                                </Tabs>
                            }
                        >
                            <Descriptions size="small" column={3}>
                                {this.state.service!.live!.public ? <Descriptions.Item
                                    label="Service Status">
                                    <Space direction="vertical">
                                        <Text type="success">Available On the Store</Text>
                                    </Space>
                                </Descriptions.Item> : ""}

                                <Descriptions.Item
                                    label="Service ID">{this.state.service!.live!.manifest.id}</Descriptions.Item>
                                <Descriptions.Item
                                    label="Name">{this.state.service!.live!.manifest.name}</Descriptions.Item>
                                <Descriptions.Item
                                    label="Creation Time">{this.state.service!.live!.createdAt}</Descriptions.Item>
                            </Descriptions>
                        </PageHeader>
                    </div> : <Skeleton loading={this.state.loading}/>
                }
            </CustomSpinner>
        )
    }

}