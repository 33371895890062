import React, {Component} from "react"
import {Admin} from "../Interfaces/IProjectSummary";
import {Button, notification, Popconfirm, Table, Tag} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {EditProjectMemberRoles} from "../Modals/EditProjectMemberRoles";
import CustomSpinner from "./CustomSpinner";
import {AddMemberToProject} from "../Modals/AddMemberToProject";
import {RootProjectContext} from "../Contexts/RootProjectContext";
import {IProjectMember} from "../Actions/Interfaces/IProject";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../Api/APIService";
import _ from "lodash";


interface Props {
    projectId: string
}

interface State {
    deleting: boolean
    admins: Admin[]
    loading: boolean
}

export default class AdminList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false,
            admins: [],
            loading: true
        }
        this.deleteAdmin = this.deleteAdmin.bind(this)
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    async deleteAdmin(email: string, instance: RetterCloudObject) {
        this.setState({deleting: true, loading: true})
        try {
            await instance.call<any>({
                method: RootProjectClassMethods.removeMember,
                body: {
                    email
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            } else {
                throw e
            }
        }
        this.setState({deleting: false, loading: false})
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <CustomSpinner spinning={this.state.loading}>
                            <Table
                                pagination={{defaultPageSize: 20}}
                                columns={[
                                    {
                                        title: <>Email <AddMemberToProject
                                            projectInstance={ctx.instance!}
                                            projectId={this.props.projectId}
                                        /></>,
                                        render: (item: IProjectMember) => <>{item.email}</>
                                    },
                                    {
                                        title: 'Roles',
                                        render: (item: IProjectMember) => {
                                            return <Tag color={item.role === 'owner' ? "geekblue" : ''}
                                                        key={item.role}>
                                                {item.role}
                                            </Tag>
                                        }
                                    },
                                    {
                                        title: 'Operations',
                                        render: (item: IProjectMember) => {
                                            return <>
                                                <Popconfirm
                                                    icon={<QuestionCircleOutlined
                                                        style={{color: 'red'}}/>}
                                                    title="Are you sure to remove this member?"
                                                    okButtonProps={{loading: this.state.deleting}}
                                                    onConfirm={async () => {
                                                        await this.deleteAdmin(item.email, ctx.instance!)
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button type={"link"} loading={this.state.deleting}
                                                            danger>Remove</Button>
                                                </Popconfirm>
                                                &nbsp;
                                                <EditProjectMemberRoles
                                                    projectInstance={ctx.instance!}
                                                    key={item.userId}
                                                    member={
                                                        {
                                                            email: item.email,
                                                            memberId: item.userId,
                                                            currentRole: item.role,
                                                            updateToken: ''
                                                        }
                                                    }
                                                    projectId={this.props.projectId}/>
                                            </>
                                        }
                                    }
                                ]}
                                dataSource={_.sortBy(ctx.detail?.members.map(m => {
                                    return {
                                        ...m,
                                        sortKey: m.email.toLowerCase()
                                    }
                                }), 'sortKey')}/>
                        </CustomSpinner>
                    )}
                </RootProjectContext.Consumer>
            </>
        );
    }
}
