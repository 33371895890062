import React, {Component} from "react";
import {Button, Divider, Form, Input, List, Modal, Select, Spin} from "antd";
import {APIService} from "../Api/APIService";
import {IProjectSummary} from "../Interfaces/IProjectSummary";
import {SelectValue} from "antd/lib/select";
import {LiveService} from "../Interfaces/Service";


const {Option} = Select;

interface Props {
    apiService: APIService
    serviceId: string
    service: LiveService
}

interface State {
    loading: boolean
    visible: boolean
    projects: IProjectSummary[]
    selectedProjectId: string | undefined
    serviceParameters: any
}

export class InstallServiceModal extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            serviceParameters: {},
            loading: false,
            visible: false,
            projects: [],
            selectedProjectId: undefined
        }
        this.installService = this.installService.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.onValuesChangeHandler = this.onValuesChangeHandler.bind(this)
    }

    async componentDidMount() {
        const projects = await this.apiService.getProjects()
        this.setState({projects})
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async installService(projectId: string, serviceId: string) {
        this.setState({loading: true});
        await this.apiService.installService(serviceId, projectId, this.state.serviceParameters)
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async (values: any) => {
        await this.installService(this.state.selectedProjectId!, this.props.serviceId)
    };

    onFinishFailed = (errorInfo: any) => {
    };

    handleSelectChange(value: SelectValue) {
        this.setState({selectedProjectId: value as any})
    }

    onValuesChangeHandler(changedValues: any, values: any) {
        this.setState({serviceParameters: values})
    }

    render() {
        const {visible, loading} = this.state;

        return (
            <>
                <Button type="primary" onClick={this.showModal}>
                    Install Service To Project
                </Button>
                <Modal
                    visible={visible}
                    title="Install Service"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>
                    ]}
                >
                    <Spin spinning={loading}>
                        <Form
                            layout={"inline"}
                            name="basic"
                            initialValues={{remember: true}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Select defaultValue="" style={{width: 200}} onChange={this.handleSelectChange}>
                                {this.state.projects.map(p => {
                                    return <Option value={p.projectId}>{p.alias}</Option>
                                })}
                            </Select>
                            <Divider/>
                            <List
                                header={<b>Depends On</b>}
                                bordered
                                dataSource={this.props.service.manifest.dependsOn}
                                renderItem={item => (
                                    <List.Item>
                                        {item}
                                    </List.Item>
                                )}
                            />
                            <Divider/>
                            <Form
                                onValuesChange={this.onValuesChangeHandler}
                                layout={"vertical"}
                                name="basic"
                            >
                                {
                                    this.props.service.manifest.serviceParameters ? this.props.service.manifest.serviceParameters.map(field => {
                                        return <>
                                            <Form.Item
                                                label={field.toLocaleUpperCase()}
                                                name={field}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </>
                                    }) : ""
                                }
                            </Form>
                            <Divider/>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Install
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
}