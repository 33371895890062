import React, {Component} from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {Table} from "antd";
import {ICloudObjectClass} from "../../Interfaces/CloudObjetsInterfaces";
import CreateClassModal from "../../Modals/CreateClassModal";
import {RootProjectContext} from "../../Contexts/RootProjectContext";
import _ from "lodash";


interface Props {
    routeComponentProps: RouteComponentProps<{ projectId: string }>
}

interface State {
}

class CloudObjectListLayout extends Component<Props, State> {
    projectId: string

    constructor(props: Props) {
        super(props);
        this.projectId = props.routeComponentProps.match.params.projectId
        this.createSuccessCallback = this.createSuccessCallback.bind(this)
    }

    async createSuccessCallback(classId: string) {
        this.props.routeComponentProps.history.push(
            `/project/${this.projectId}/Classes/${classId}`
        )
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <Table
                            pagination={{defaultPageSize: 50}}
                            columns={[
                                {
                                    title: <>Class Id <CreateClassModal projectId={this.projectId}
                                                                        successCallback={this.createSuccessCallback}/></>,
                                    render: (p: ICloudObjectClass) => <>
                                        <Link
                                            to={`/project/${this.projectId}/Classes/${p.classId}`}>
                                            {p.classId}
                                        </Link>
                                    </>
                                }
                            ]}
                            dataSource={_.sortBy(ctx.detail?.classes.map(c => {
                                return {...c, sortKey: c.classId.toLowerCase()}
                            }), 'sortKey') || []}/>
                    )}
                </RootProjectContext.Consumer>
            </>
        );
    }
}

CloudObjectListLayout.contextType = RootProjectContext

export default CloudObjectListLayout
