import React, {Component} from "react";
import {Skeleton, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


interface Props{
    spinning: boolean
    children: any
    skeleton?: boolean
}
interface State{
    skeleton: boolean
}

const spinIcon = <LoadingOutlined spin />;

export default class CustomSpinner extends Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            skeleton: props.skeleton === undefined ? true : props.skeleton
        }
    }
    render() {
        return (
            <>
                <Spin size={"large"} indicator={<Spin indicator={spinIcon} />} spinning={this.props.spinning}>
                    {
                        this.state.skeleton ? this.props.spinning ? <Skeleton active loading={this.props.spinning}/> : this.props.children : this.props.children
                    }
                </Spin>
            </>
        );
    }
}