import React, {Component} from "react";
import Editor from "@monaco-editor/react";
import {AutoComplete, Button, Empty, Form, Input, notification, Select} from "antd";
import CustomSpinner from "../../../Components/CustomSpinner";
import {RoleItem} from "../../../Interfaces/IRoles";
import RBS, {RetterCallResponse, RetterCloudObject, RetterCloudObjectState} from "@retter/sdk";
import {GlobalHelpers} from "../../../GlobalHelpers";


interface Props {
    projectId: string
    input: {
        classId: string
        instanceId: string
    }
    rootSdkInstance: RBS
    thisClassInstance?: RetterCloudObject
}

interface State {
    cosState?: RetterCallResponse<RetterCloudObjectState>
    loading: boolean
    roles: RoleItem[]
    filteredRoles: RoleItem[]
}

export class CosStatesTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            roles: [],
            filteredRoles: []
        }
        this.execute = this.execute.bind(this)
        this.identityCompleteOnFocus = this.identityCompleteOnFocus.bind(this)
        this.execute = this.execute.bind(this)
        this.identityCompleteOnFocus = this.identityCompleteOnFocus.bind(this)
        this.handleRoleInputSearch = this.handleRoleInputSearch.bind(this)
    }

    async execute(form: any) {
        this.setState({
            loading: true
        })
        const user = (form.userId && form.userId !== '') && (form.identity && form.identity !== '') ? form : undefined
        let ins = this.props.thisClassInstance
        let result;
        if (ins) {
            if (user) {
                try {
                    const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, user)
                    result = await ins.getState({token})
                    this.setState({
                        cosState: result,
                    })
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.message) {
                        notification.error({
                            placement: "bottomRight",
                            message: e.response.data && e.response.data.issues ? e.response.data.issues.map((i: any) => i.message).join(', ') : e.response.data.message
                        })
                    } else {
                        notification.error({
                            placement: "bottomRight",
                            message: 'Error'
                        })
                    }
                    this.setState({loading: false})
                }
            } else {
                try {
                    const currentUser = await this.props.rootSdkInstance.getCurrentUser()
                    if (!currentUser) throw new Error('current user not found')
                    const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, {
                        identity: currentUser.identity!,
                        userId: currentUser.userId!
                    })
                    result = await ins.getState({
                        token,
                        httpMethod: form.httpMethod
                    })
                    this.setState({
                        cosState: result,
                    })
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.message) {
                        notification.error({
                            placement: "bottomRight",
                            message: e.response.data && e.response.data.issues ? e.response.data.issues.map((i: any) => i.message).join(', ') : e.response.data.message
                        })
                    } else {
                        notification.error({
                            placement: "bottomRight",
                            message: 'Error'
                        })
                    }
                    this.setState({loading: false})
                }
            }

        }
        this.setState({
            loading: false
        })
    }

    async identityCompleteOnFocus() {
        const projectRoles: any = undefined//await this.props.apiService.getProjectRoles(this.props.projectId)
        if (projectRoles)
            this.setState({
                roles: projectRoles.projectRoles
            })
    }

    handleRoleInputSearch(key: string) {
        this.setState({
            filteredRoles: this.state.roles.filter(r => r.roleName.includes(key.toLowerCase())) || []
        })
    }

    render() {
        return (
            <>
                <Form
                    name="call_instance_state"
                    layout="inline"
                    onFinish={this.execute}
                >
                    <Form.Item name="identity" label="Identity">
                        <AutoComplete
                            onFocus={this.identityCompleteOnFocus}
                            onSearch={this.handleRoleInputSearch}
                            style={{width: 200}}
                            placeholder="Identity"
                        >
                            {this.state.filteredRoles.map(r => {
                                return <AutoComplete.Option key={r.roleName} value={r.roleName}>
                                    {r.roleName}
                                </AutoComplete.Option>
                            })}
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item name="userId" label="User Id">
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"httpMethod"}>
                        <Select defaultValue="post" style={{ width: 120 }}>
                            <Select.Option value="post">POST</Select.Option>
                            <Select.Option value="get">GET</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={this.state.loading}
                                type={"primary"}>Get</Button>
                    </Form.Item>
                </Form>
                <br/>
                <CustomSpinner spinning={this.state.loading}>
                    {
                        this.state.cosState ? <>
                            <Editor
                                options={{readOnly: true}}
                                height={'80vh'}
                                language={'json'}
                                defaultValue={JSON.stringify(this.state.cosState.data, null, 2)}
                            />
                        </> : <>
                            <Empty description={'No Data'}/>
                        </>
                    }
                </CustomSpinner>
            </>
        );
    }
}
