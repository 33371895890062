import React, {Component} from "react";
import {RootProjectClassEnums, RootProjectClassMethods} from "../../../Api/APIService";
import CustomSpinner from "../../../Components/CustomSpinner";
import {Collapse} from "antd";
import {CosLogResponse, CosLogsItem, CosLogUtil} from "../../../Interfaces/CosInterfaces";
import ReactJson from 'react-json-view'
import {IProjectContext, RootProjectContext} from "../../../Contexts/RootProjectContext";


interface Props {
    projectId: string
    requestId: string
    classId: string
}

interface State {
    loading: boolean
    logs: CosLogsItem[]
    activeLogPane: string
}

class CosMethodResponseLogs extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            logs: [],
            activeLogPane: ''
        }
        this.init = this.init.bind(this)
    }

    async componentDidMount() {
        this.ctx = this.context
        await this.init()
    }

    async init() {
        if (!this.ctx) throw new Error('ctx not found')
        this.setState({
            loading: true
        })
        const instance = await this.ctx.rootRbsSdk?.getCloudObject({
            classId: RootProjectClassEnums.RetterClass,
            instanceId: this.props.projectId + '_' + this.props.classId
        })
        if (!instance) throw new Error('instance not found')
        const res = await instance.call<CosLogResponse>({
            method: RootProjectClassMethods.getClassLogsByRequestId,
            body: {
                requestId: this.props.requestId
            }
        })
        if (res && res.data && res.data.logs) {
            this.setState({
                logs: res.data.logs
            })
        }
        this.setState({
            loading: false
        })
    }

    prepareLogString = (logs: CosLogUtil[]) => {
        return logs.map(log => {
            const header = <b>{log.time}&nbsp;&nbsp;&nbsp;{log.type}</b>
            try {
                const json = JSON.parse(log.log)
                if (typeof json === 'object') {
                    return <p>{header}&nbsp;&nbsp;&nbsp;<ReactJson name={false} collapsed={true}
                                                                   displayDataTypes={false}
                                                                   src={JSON.parse(log.log)}/></p>
                } else {
                    return <p>{header}&nbsp;&nbsp;&nbsp;{log.log}</p>
                }
            } catch (e) {
                return <p>{header}&nbsp;&nbsp;&nbsp;{log.log}</p>
            }
        })

    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <CustomSpinner spinning={this.state.loading}>
                            <Collapse defaultActiveKey={[this.state.activeLogPane]} onChange={(e) => {
                                this.setState({
                                    activeLogPane: e.toString()
                                })
                            }}>
                                {
                                    this.state.logs.map((l, i) => <Collapse.Panel
                                        header={<>{l.stepId}</>}
                                        key={i}>
                                        <p>{this.prepareLogString(l.logs)}</p>
                                    </Collapse.Panel>)
                                }
                            </Collapse>
                        </CustomSpinner>
                    )}
                </RootProjectContext.Consumer>
            </>
        );
    }
}

CosMethodResponseLogs.contextType = RootProjectContext
export default CosMethodResponseLogs
