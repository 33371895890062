import React, {Component} from "react";
import {Badge, Table, Tooltip, Typography} from "antd";
import LogDetailModal from "../Modals/LogDetailModal";
import moment from "moment";
import {CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleOutlined} from "@ant-design/icons";

const {Text} = Typography;

interface Props {
    projectId: string
    items: any[]
}

interface State {
    logDetail?: any
    selectedRowKeys: string[]
}

export class LogsTable extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedRowKeys: []
        }
    }

    onRowClick = (data: any) => {
        let openDetail = false
        if (this.state.selectedRowKeys.includes(data.key)) {
            openDetail = true
        }
        this.setState({
            selectedRowKeys: [data.key]
        })
        if (openDetail) {
            this.setState({
                logDetail: undefined
            })
            this.setState({
                logDetail: data
            })
        }
    }

    onDetailCancel = () => {
        this.setState({
            logDetail: undefined
        })
    }

    render() {
        return (
            <>
                {
                    this.state.logDetail ?
                        <LogDetailModal
                            onCancel={this.onDetailCancel}
                            projectId={this.props.projectId}
                            logSummary={this.state.logDetail}
                            show={!!this.state.logDetail}/> : null
                }
                <Table
                    onRow={(data: any, index?: number) => {
                        return {
                            onClick: event => {
                                this.onRowClick(data)
                            }
                        }
                    }}
                    pagination={{defaultPageSize: 30}}
                    bordered
                    dataSource={this.props.items}
                    scroll={{x: '100vw'}}
                    style={{cursor: 'pointer'}}
                    columns={[
                        {
                            fixed: true,
                            title: <>requesttimeepoch <Tooltip title="Local time zone">
                                <span><InfoCircleOutlined/></span>
                            </Tooltip></>,
                            dataIndex: 'requesttimeepoch',
                            width: 200,
                            render: (requesttimeepoch: string) => {
                                return moment(requesttimeepoch.replace(' ', 'T') + 'Z')
                                    .format('YYYY-MM-DD HH:mm:ss.SSS')
                            }
                        },
                        {
                            title: 'statuscode',
                            dataIndex: 'statuscode',
                            width: 100,
                            render: (statuscode: number) => {
                                if (statuscode >= 200 && statuscode < 400) {
                                    return <Text type="success">{statuscode}</Text>
                                } else {
                                    return <Text type="danger">{statuscode}</Text>
                                }
                            }
                        },
                        {
                            title: 'classid',
                            dataIndex: 'classid',
                            width: 200
                        },
                        {
                            title: 'instanceid',
                            dataIndex: 'instanceid',
                            width: 200
                        },
                        {
                            title: 'methodname',
                            dataIndex: 'methodname',
                            width: 200
                        },
                        {
                            title: 'identity',
                            dataIndex: 'identity',
                            width: 200
                        },
                        {
                            title: 'userid',
                            dataIndex: 'userid',
                            width: 400
                        },
                        {
                            title: 'requestid',
                            dataIndex: 'requestid',
                            width: 175,
                        },
                        {
                            title: 'platform',
                            dataIndex: 'platform',
                            width: 180,
                        },
                        {
                            title: <>year <Tooltip title="UTC">
                                <span><InfoCircleOutlined/></span>
                            </Tooltip></>,
                            dataIndex: 'year',
                            width: 80,
                        },
                        {
                            title: <>month <Tooltip title="UTC">
                                <span><InfoCircleOutlined/></span>
                            </Tooltip></>,
                            dataIndex: 'month',
                            width: 80,
                        },
                        {
                            title: <>day <Tooltip title="UTC">
                                <span><InfoCircleOutlined/></span>
                            </Tooltip></>,
                            dataIndex: 'day',
                            width: 80,
                        },
                        {
                            title: <>hour <Tooltip title="UTC">
                                <span><InfoCircleOutlined/></span>
                            </Tooltip></>,
                            dataIndex: 'hour',
                            width: 80,
                        }
                    ].map(i => {
                        return {...i, ...{ellipsis: true}}
                    })}
                    rowSelection={{selectedRowKeys: this.state.selectedRowKeys, hideSelectAll: true, type: 'radio'}}/>
            </>
        );
    }
}
