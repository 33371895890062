import React, {Component} from "react";
import {Breadcrumb, Button, Col, Descriptions, Layout, PageHeader, Result, Row, Table, Tabs, Tag} from 'antd';
import {APIService} from "../../Api/APIService";
import CustomSpinner from "../../Components/CustomSpinner";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    IBillingAccountInfo,
    IBillingAccountMember,
    IProjectBillingAccount
} from "../../Interfaces/BillingAccountInterfaces";
import BillingAccountOverview from "./BillingAccountOverview";
import {BillingAccountInvoices} from "./Components/BillingAccountInvoices";

const {TabPane} = Tabs
const {Content} = Layout;


interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps<{ billingAccountId: string }>
}

interface State {
    loading: boolean
    billingAccountInfo?: IBillingAccountInfo
}

export default class BillingAccountDetail extends Component<Props, State> {
    private readonly apiService: APIService;
    private readonly billingAccountId: string;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.billingAccountId = props.routeComponentProps.match.params.billingAccountId

        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        const response = await this.apiService.getBillingAccountInfo(this.billingAccountId)
        if (response)
            this.setState({billingAccountInfo: response})
        this.setState({loading: false})
    }

    render() {
        return (
            <Layout>
                <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                        }}
                    >
                        <div>
                            {
                                !this.state.billingAccountInfo && !this.state.loading ? <>
                                    <Result
                                        status="warning"
                                        title="Billing account not found!"
                                        extra={
                                            <Button type="primary" key="console" href={'/billing'}>
                                                Go to billing page
                                            </Button>
                                        }
                                    />
                                </> : <>


                                    <CustomSpinner spinning={this.state.loading}>
                                        {
                                            this.state.billingAccountInfo ?
                                                <>
                                                    <PageHeader
                                                        ghost={false}
                                                        onBack={() => window.history.back()}
                                                        title={this.props.routeComponentProps.match.params.billingAccountId}
                                                        subTitle="Billing"
                                                        extra={[]}
                                                    >
                                                        <Descriptions size="small" column={3}>
                                                            <Descriptions.Item
                                                                label="Billing Account ID">{this.billingAccountId}</Descriptions.Item>
                                                        </Descriptions>
                                                        <Row gutter={[16, 16]}>
                                                            <Col span={12}>
                                                                <Tabs defaultActiveKey="1">
                                                                    <TabPane tab="Invoices" key="1">
                                                                        <BillingAccountInvoices
                                                                            billingAccountId={this.billingAccountId}
                                                                            apiService={this.apiService}/>
                                                                    </TabPane>
                                                                    <TabPane tab="Projects" key="2">
                                                                        <Table
                                                                            pagination={{defaultPageSize: 20}}
                                                                            columns={[
                                                                                {
                                                                                    title: 'ID',
                                                                                    render: (item: IProjectBillingAccount) => <>
                                                                                        <Link
                                                                                            to={'/project/' + item.billingAccountProjectId + '/Overview'}>{item.billingAccountProjectId}</Link>
                                                                                    </>
                                                                                }
                                                                            ]}
                                                                            dataSource={this.state.billingAccountInfo.projects}/>
                                                                    </TabPane>
                                                                    <TabPane tab="Members" key="3">
                                                                        <Table
                                                                            pagination={{defaultPageSize: 20}}
                                                                            columns={[
                                                                                {
                                                                                    title: 'Email',
                                                                                    render: (item: IBillingAccountMember) => <>{item.profile.email}</>
                                                                                },
                                                                                {
                                                                                    title: 'Roles',
                                                                                    render: (item: IBillingAccountMember) => {
                                                                                        return item.account.roles.map(role => {
                                                                                            return <>
                                                                                                <Tag color={"geekblue"}
                                                                                                     key={role}>
                                                                                                    {role}
                                                                                                </Tag>
                                                                                            </>
                                                                                        })
                                                                                    }
                                                                                }
                                                                            ]}
                                                                            dataSource={this.state.billingAccountInfo.members}/>
                                                                    </TabPane>
                                                                </Tabs>
                                                            </Col>
                                                            <Col span={12}>
                                                                <BillingAccountOverview apiService={this.apiService}
                                                                                        billingAccountId={this.billingAccountId}/>
                                                            </Col>
                                                        </Row>
                                                    </PageHeader>
                                                </>
                                                : ""
                                        }
                                    </CustomSpinner>
                                </>
                            }
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}