import {Button, Form, Input, Modal, Select} from "antd";
import React, {Component} from "react";
import {ClassTemplateListItem, RootProjectClassMethods} from "../Api/APIService";

import CustomSpinner from "../Components/CustomSpinner";
import {IProjectContext, RootProjectContext} from "../Contexts/RootProjectContext";

interface Props {
    projectId: string
    successCallback: (classId: string) => void
}

interface State {
    loading: boolean
    visible: boolean
    classTemplates?: ClassTemplateListItem[]
}

class CreateClassModal extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            visible: false
        }
        this.setIsModalVisible = this.setIsModalVisible.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    async componentDidMount() {
        this.ctx = this.context
    }

    async createClass(payload: { classId: string}) {
        this.setState({
            loading: true
        })

        const response = await this.ctx?.instance?.call<any>({
            method: RootProjectClassMethods.createClass,
            body: payload
        })
        if (response && response.status === 200)
            await this.props.successCallback(payload.classId)
        this.setState({
            loading: false
        })
    }

    setIsModalVisible(visible: boolean) {
        this.setState({
            visible
        })
    }

    showModal() {
        this.setIsModalVisible(true);
    };

    async handleOk(values: any) {
        await this.createClass(values)
    };

    handleCancel() {
        this.setIsModalVisible(false);
    };

    render() {
        return (
            <>
                <Button type="link" onClick={this.showModal}>
                    Create
                </Button>
                <Modal title="Create Class"
                       visible={this.state.visible}
                       footer={[
                           <Button type={"link"} onClick={this.handleCancel}>
                               Cancel
                           </Button>,
                           <Button htmlType={'submit'} type={'primary'} form={'create_class'}>
                               Create
                           </Button>
                       ]}
                       okText={'Create'}
                       onCancel={this.handleCancel}>
                    <CustomSpinner spinning={this.state.loading}>
                        <Form
                            id={'create_class'}
                            labelCol={{span: 5}}
                            onFinish={this.handleOk}
                            layout="horizontal"
                            name="horizontal"
                            autoComplete="off"
                            wrapperCol={{span: 14, offset: 2}}
                        >
                            <Form.Item
                                label="Class ID"
                                name="classId"
                                rules={[{required: true, message: 'Please input class id!'}]}
                            >
                                <Input autoFocus/>
                            </Form.Item>
                        </Form>
                    </CustomSpinner>
                </Modal>
            </>
        );
    }
}

CreateClassModal.contextType = RootProjectContext

export default CreateClassModal