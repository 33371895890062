import React from 'react';
import ReactDOM from 'react-dom';
import {GoogleReCaptchaProvider,} from "react-google-recaptcha-v3";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";
import './index.css'
import autoGenConfig from './config/autoGenConfig';

ConfigProvider.config({
    prefixCls: 'ant',
    theme: {
        primaryColor: '#0144e4',
        //errorColor: '#ff4d4f',
        //warningColor: '#faad14',
        //successColor: '#52c41a',
        //infoColor: '#1890ff',
    }
})

ReactDOM.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={autoGenConfig.captchaKey}
            scriptProps={{async: true, defer: true, appendTo: "body"}}>
            <ConfigProvider>
                <App/>
            </ConfigProvider>
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals(console.log);
