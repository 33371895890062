import React, {Component} from "react";
import {Button, Empty, Layout, notification, Popconfirm, Table, Tag} from 'antd';
import {APIService, RootProjectClassEnums, RootProjectClassMethods} from "../Api/APIService";
import {CreateNewProjectModal} from "../Modals/CreateNewProjectModal";
import {IProjectSummary} from "../Interfaces/IProjectSummary";
import CustomSpinner from "../Components/CustomSpinner";
import emptySvg from "../empty.svg"
import {RouteComponentProps} from "react-router-dom";
import {IUserProjectItem, userStore} from "../Actions/Stores/UserStore";
import {ActionTypes, topMenuStore} from "../Actions/Actions";


interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps
}

interface State {
    loading: boolean
    projects?: IUserProjectItem[]
    projectDetail?: IProjectSummary
    adminEmail?: string
    adminId?: string
    ownProjects: { adminId: string, projectId: string }[]
}

export default class ProjectsLayout extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        const admin = JSON.parse(localStorage.getItem('user')!)
        this.apiService = props.apiService

        this.state = {
            loading: true,
            adminEmail: admin ? admin.email : undefined,
            adminId: admin ? admin.username : undefined,
            ownProjects: []
        }
        this.leaveTheProject = this.leaveTheProject.bind(this)
    }

    async componentDidMount() {
        const state = userStore.getState()
        if (state) {
            this.setState({
                projects: state.data.myProjects.map(mp => {
                    return {...mp, ...{status: mp.status ? mp.status : 'active'}}
                }),
                loading: false
            })
        }
        userStore.subscribe(() => {
            const state = userStore.getState()
            this.setState({
                projects: state.data.myProjects.map(mp => {
                    return {...mp, ...{status: mp.status ? mp.status : 'active'}}
                }),
                loading: false
            })
        })
        if (this.state.projects) {
            this.setState({
                loading: false
            })
        }
        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED, data: {
                title: 'Projects'
            }
        })
    }

    componentWillUnmount() {
    }

    async leaveTheProject(projectId: string) {
        const instance = await this.props.apiService.rootRbsSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: projectId
        })
        try {
            await instance.call<any>({
                method: RootProjectClassMethods.leaveProject,
                body: {
                    projectId
                }
            })
            if (this.state.projects)
                this.setState({
                    projects: this.state.projects?.filter(p => p.projectId !== projectId)
                })
        } catch (e) {
            let mes = 'error'
            if (e.response) {
                mes = e.response.data
            }
            notification.error({
                placement: 'bottomRight',
                message: mes
            })
        }

    }

    goToProject = (projectId: string) => {
        this.props.routeComponentProps.history.push(`/project/${projectId}/Overview`)
    }

    render() {
        return (
            <Layout>
                <CustomSpinner spinning={this.state.loading}>
                    <div className="site-card-wrapper">
                        {
                            Array.isArray(this.state.projects) && this.state.projects.length > 0 ?
                                <>
                                    <Table
                                        size={"middle"}
                                        scroll={{x: 200}}
                                        pagination={{defaultPageSize: 50}}
                                        dataSource={this.state.projects}
                                        columns={
                                            [
                                                {
                                                    title: <>Alias <CreateNewProjectModal
                                                        onSuccess={(projectId) => {
                                                            this.props.routeComponentProps.history.push(`/project/${projectId}/Overview`)
                                                        }}
                                                        apiService={this.apiService}/></>,
                                                    render: (project: IProjectSummary) => {
                                                        return <Button type={"link"} onClick={() => {
                                                            this.goToProject(project.projectId)
                                                        }}>{project.alias}</Button>
                                                    }
                                                },
                                                {
                                                    title: "Project Id",
                                                    render: (project: IProjectSummary) => {
                                                        return <>
                                                            {project.projectId}
                                                        </>
                                                    }
                                                },
                                                {
                                                    title: "Status",
                                                    dataIndex: 'status',
                                                    defaultFilteredValue: ['active'],
                                                    filters: [
                                                        {
                                                            text: 'Active',
                                                            value: 'active',
                                                        },
                                                        {
                                                            text: 'Deactivated',
                                                            value: 'deactivated',
                                                        }
                                                    ],
                                                    onFilter: (value, record) => record.status === value,
                                                    render: (val, rec) => {
                                                        return (rec.status || 'active') === 'active' ?
                                                            <Tag>{rec.status}</Tag> :
                                                            <Tag color="red">{rec.status}</Tag>
                                                    }
                                                },
                                                {
                                                    title: "",
                                                    render: (project: IProjectSummary) => {
                                                        return <>
                                                            {this.state.projects?.find(p => p.projectId === project.projectId)?.role === 'owner' ?
                                                                <Tag>owner</Tag> : <>
                                                                    <Popconfirm
                                                                        title="Are you sure to leave this project?"
                                                                        onConfirm={async () => {
                                                                            await this.leaveTheProject(project.projectId)
                                                                        }}
                                                                        okType={'danger'}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button type={'link'}
                                                                                danger>Leave</Button>
                                                                    </Popconfirm>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                }
                                            ]
                                        }/>
                                </> :
                                <Empty
                                    image={emptySvg}
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    description={
                                        "No projects"
                                    }
                                >
                                    <CreateNewProjectModal
                                        onSuccess={(projectId) => {
                                            this.props.routeComponentProps.history.push(`/project/${projectId}/Overview`)
                                        }}
                                        apiService={this.apiService}/>
                                </Empty>
                        }
                    </div>
                </CustomSpinner>
            </Layout>
        )
    }
}
