import React, {Component} from "react";
import {Col, Row} from "antd";
import ProjectsLayout from "./ProjectsLayout";
import {APIService} from "../Api/APIService";
import ServicesLayout from "./ServiceLayouts/ServicesLayout/ServicesLayout";
import {RouteComponentProps} from "react-router-dom";


interface Props {
    routeComponentProps: RouteComponentProps
    apiService: APIService
}

interface State {

}

export class HomePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <ProjectsLayout apiService={this.props.apiService} routeComponentProps={this.props.routeComponentProps}/>
                    </Col>
                </Row>
            </>
        );
    }

}