import React, {Component} from "react";
import {Button, Descriptions, message, Modal, Popconfirm} from "antd";
import {UserProviderContext} from "../Contexts/UserProviderContext";
import CustomSpinner from "../Components/CustomSpinner";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../Api/APIService";


export interface Props {
    visible: boolean
    cancelCallback: () => void
}

export interface State {
    loading: boolean
}

class UserSettingsModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    resetCredentials = async (userInstance?: RetterCloudObject) => {
        if(!userInstance) throw new Error('user instance error')
        this.setState({loading: true})
        await userInstance.call({
            method: RootProjectClassMethods.resetCredentials,
        })
        this.setState({loading: false})
    }

    copy2CP = async (value: string, mes: string) => {
        await navigator.clipboard.writeText(value)
        message.success(mes + ' copied!');
    }

    render() {
        return (
            <>
                <UserProviderContext.Consumer>
                    {(ctx) => (
                        ctx && ctx.detail ? <>

                            <Modal title="Settings" width={777} visible={this.props.visible}
                                   onCancel={this.props.cancelCallback}
                                   footer={[
                                       <Button type={'default'} onClick={this.props.cancelCallback}>Cancel</Button>
                                   ]}>
                                <CustomSpinner spinning={this.state.loading}>
                                    <Descriptions
                                        bordered
                                        size={'middle'}
                                        extra={[
                                            <Popconfirm
                                                title="Are you sure to regenerate secret key?"
                                                onConfirm={async ()=>{await this.resetCredentials(ctx.instance)}}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type={'link'}> Reset Credentials</Button>
                                            </Popconfirm>
                                        ]}
                                    >
                                        {
                                            (ctx.detail && ctx.detail.credentials && ctx.detail.credentials.secretId && ctx.detail.credentials.secretKey) ? <>
                                                <Descriptions.Item span={9} label={<>Secret Id </>}>
                                                    {ctx.detail.credentials.secretId.substr(0, 7)}{new Array(ctx.detail.credentials.secretId.length! - 7).fill('*').join('')}<Button
                                                    type={'link'}
                                                    onClick={async ()=>{await this.copy2CP(ctx.detail!.credentials!.secretId, 'Secret Id')}}>Copy</Button>
                                                </Descriptions.Item>
                                                <Descriptions.Item span={9} label={<>Secret Key </>}>
                                                    {ctx.detail.credentials.secretKey.substr(0, 7)}{new Array(ctx.detail.credentials.secretKey.length - 7).fill('*').join('')}<Button
                                                    type={'link'}
                                                    onClick={async ()=>{await this.copy2CP(ctx.detail!.credentials!.secretKey, 'Secret Key')}}>Copy</Button>
                                                </Descriptions.Item>
                                            </> : <>
                                                <Descriptions.Item span={9}>
                                                    No credentials
                                                </Descriptions.Item>
                                            </>
                                        }

                                    </Descriptions>
                                </CustomSpinner>
                            </Modal>
                        </> : null
                    )}
                </UserProviderContext.Consumer>
            </>
        );
    }
}

UserSettingsModal.contextType = UserProviderContext

export default UserSettingsModal
