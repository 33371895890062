import React, {Component} from "react";
import {Button, Form, Input, Modal, Select, Spin} from "antd";
import {APIService, ConsoleServiceActions} from "../Api/APIService";

const {Option} = Select;

interface Props {
    apiService: APIService
    serviceId: string
    successCallback: Function
}

interface State {
    loading: boolean
    visible: boolean
    developerRoles: string[]
    selectedRoles: string[]
}

export class AddMemberToService extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            loading: false,
            visible: false,
            developerRoles: [],
            selectedRoles: []
        }
        this.addMember = this.addMember.bind(this)
        this.selectRolesChange = this.selectRolesChange.bind(this)
    }

    async componentDidMount() {
        const roles = await this.apiService.getSupportedActionList()
        this.setState({
            developerRoles: roles.serviceActions
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async addMember(email: string, roles: string[]) {
        this.setState({loading: true});
        await this.apiService.addMemberToService(this.props.serviceId, email, roles)
        await this.props.successCallback()
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async (values: any) => {
        const {email, roles} = values
        await this.addMember(email, roles)

    };

    onFinishFailed = (errorInfo: any) => {
    };


    selectRolesChange(values: string[], option: any) {
        this.setState({
            selectedRoles: values
        })
    }

    render() {
        const {visible, loading} = this.state;

        return (
            <>
                <Button type="default" onClick={this.showModal}>
                    Add New Member
                </Button>
                <Modal
                    visible={visible}
                    title="Add New Member"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type={'link'} onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key='confirm' form={'add_service_member'} type="primary" htmlType="submit">
                            Add
                        </Button>
                    ]}
                >
                    <Spin spinning={loading}>
                        <Form
                            layout={'vertical'}
                            id={'add_service_member'}
                            name="vertical"
                            initialValues={{remember: true}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Please input email address'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Actions"
                                name="roles"
                                rules={[{required: true, message: 'Please select action'}]}>
                                <Select
                                    onChange={this.selectRolesChange}
                                    mode="multiple"
                                    value={this.state.selectedRoles.map(r => {
                                        return r
                                    })}
                                    allowClear
                                    placeholder="Add action"
                                >
                                    {
                                        Object.values(ConsoleServiceActions).map(r =>
                                            <Option value={r} data={r}>{r}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
}