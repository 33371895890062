import React, {useState} from "react";
import {Col, Divider, Layout, message, notification, Row, Spin} from "antd";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import logo from "../logo.svg";
import {Auth} from "aws-amplify";
import SignInForm from "../Components/SignInForm";
import SignUpForm from "../Components/SignUpForm";
import ConfirmSignUpForm from "../Components/ConfirmSignUpForm";
import ForgotPasswordForm from "../Components/ForgotPasswordForm";
import ForgotPasswordSubmitForm from "../Components/ForgotPasswordSubmitForm";
import autoGenConfig from '../config/autoGenConfig'
import {LoadingOutlined} from "@ant-design/icons";
import {APIService, RootProjectClassEnums, RootProjectClassMethods} from "../Api/APIService";
import ConfirmSignInForm from "../Components/ConfirmSignInForm";
import {RetterCloudObject} from "@retter/sdk";
import ConfirmInvitationForm from "../Components/ConfirmInvitationForm";

const {Content} = Layout;
const spinIcon = <LoadingOutlined spin/>;

interface Props {
    apiService: APIService
}

const WelcomeLayout = (props: Props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    let [userInstance, setUserInstance] = useState<RetterCloudObject>();
    let [invitationInstance, setInvitationInstance] = useState<RetterCloudObject>();
    let [loading, setLoading] = useState(false);
    let [formState, setFormState] = useState("login");
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [forgotPasswordMail, setForgotPasswordMail] = useState("");

    const navigateTo = (type: string) => {
        setFormState(type);
    };

    const onSignInPressed = async (values: { email: string }) => {
        try {
            setLoading(true);
            const userObject = await props.apiService.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.EmailAuthenticator,
                body: {
                    email: values.email
                }
            })
            await userObject.call<any>({
                method: RootProjectClassMethods.sendEmailOtp,
                body: {}
            })
            setUserInstance(userObject)
            setLoading(false)
            navigateTo("confirmSignIn");
        } catch (e) {
            setLoading(false);
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message
                })
            } else if (e.code === "UserNotConfirmedException") {
                navigateTo("confirmSignIn");
                return;
            } else {
                message.error(e.message);
            }
        }
    };

    const onConfirmSignIn = async (otp: string) => {
        if (!userInstance) throw new Error('user instance is null')
        setLoading(true)
        let result;
        try {
            result = await userInstance.call<any>({
                method: RootProjectClassMethods.validateEmailOtp,
                body: {
                    otp
                }
            })
            if (result && result.data.loginStatus === 'INVITATION_CONFIRM_REQUIRED') {
                navigateTo('confirmInvitation')
            } else {
                setUserInstance(userInstance)
                await props.apiService.rootRbsSdk.authenticateWithCustomToken(result.data.customToken)
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message
                })
            }
        }
        setLoading(false)
    }

    const onInvitationConfirmPressed = async (code: string) => {
        try {
            setLoading(true);
            const invitationInstance = await props.apiService.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Invitation,
                instanceId: userInstance?.instanceId
            })
            const userObject = await props.apiService.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.EmailAuthenticator,
                body: {
                    email: invitationInstance?.instanceId
                }
            })
            const response = await userObject.call<any>({
                method: RootProjectClassMethods.invitationConfirm,
                body: {
                    code
                }
            })
            setUserInstance(userObject)
            await props.apiService.rootRbsSdk.authenticateWithCustomToken(response.data.customToken)
            setLoading(false)
            navigateTo("confirmSignIn");
        } catch (e) {
            setLoading(false);
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message
                })
            } else if (e.code === "UserNotConfirmedException") {
                navigateTo("confirmSignIn");
                return;
            } else {
                message.error(e.message);
            }
        }
    }

    const onSignUpPressed = async (values: any) => {
        setLoading(true);
        const token = await executeRecaptcha("register");
        setUsername(values.email);
        setPassword(values.password);

        const captchaObject: any = {
            Name: 'recaptchaToken',
            Value: token,
        }

        const captchaSiteKey: any = {
            Name: 'siteKey',
            Value: autoGenConfig.captchaKey || '',
        }

        try {
            const response = await Auth.signUp({
                username: values.email,
                password: values.password,
                validationData: [captchaObject, captchaSiteKey],
            });
            setLoading(false);
            if (response.user) {
                navigateTo("confirmSignUp");
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const onConfirmSignUpPressed = async ({email, code, password}: any) => {
        try {
            setLoading(true);
            await Auth.confirmSignUp(email, code, password);
            setLoading(false);
            navigateTo("login");
        } catch (err) {
            setLoading(false);
            message.error(err.message);
        }
    };

    const onForgotPasswordPressed = async (email: string) => {
        setLoading(true);
        setForgotPasswordMail(email);
        try {
            const response = await Auth.forgotPassword(email);
            setLoading(false);
            if (response.CodeDeliveryDetails) {
                navigateTo("forgotPasswordSubmit");
            }
        } catch (err) {
            setLoading(false);
            message.error(err.message);
            // captchaRef?.current?.reset();
        }
    };

    const onForgotPasswordSubmitPressed = async ({
                                                     email,
                                                     code,
                                                     password,
                                                 }: any) => {
        try {
            setLoading(true);
            await Auth.forgotPasswordSubmit(email, code, password);
            setLoading(false);
            navigateTo("login");
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <Row justify="center" align="middle" style={{minHeight: "90vh"}}>
                <Col xs={{span: 16}} lg={{span: 10}} style={{maxWidth: '450px'}}>
                    <Spin spinning={loading} indicator={<Spin indicator={spinIcon}/>}>
                        <Content style={{overflow: "initial"}}>
                            <div
                                className={"site-layout-background"}
                                style={{padding: 24, textAlign: "center"}}
                            >
                                <img
                                    src={logo}
                                    style={{width: '15em'}}
                                    alt={"RTBS"}
                                />
                                <Divider/>
                                {formState === "login" && (
                                    <SignInForm
                                        loading={loading}
                                        onSignInPressed={onSignInPressed}
                                        onSignUpPressed={() => navigateTo("register")}
                                        onForgotPasswordPressed={() => navigateTo("forgotPassword")}
                                    />
                                )}
                                {formState === "register" && (
                                    <SignUpForm
                                        loading={loading}
                                        onSignUpPressed={onSignUpPressed}
                                        onSignInPressed={() => navigateTo("login")}
                                    />
                                )}
                                {formState === "confirmSignUp" && (
                                    <ConfirmSignUpForm
                                        loading={loading}
                                        onConfirmSignUpPressed={onConfirmSignUpPressed}
                                        onSignInPressed={() => navigateTo("login")}
                                        username={username}
                                        password={password}
                                    />
                                )}
                                {formState === "forgotPassword" && (
                                    <ForgotPasswordForm
                                        loading={loading}
                                        onForgotPasswordPressed={onForgotPasswordPressed}
                                        onSignInPressed={() => navigateTo("login")}
                                    />
                                )}
                                {formState === "forgotPasswordSubmit" && (
                                    <ForgotPasswordSubmitForm
                                        loading={loading}
                                        onForgotPasswordSubmitPressed={onForgotPasswordSubmitPressed}
                                        onSignInPressed={() => navigateTo("login")}
                                        username={forgotPasswordMail}
                                    />
                                )}
                                {formState === "confirmSignIn" && (
                                    <ConfirmSignInForm
                                        onConfirmPressed={onConfirmSignIn}
                                        apiService={props.apiService}
                                        loading={loading}
                                        onSignInPressed={() => navigateTo("login")}
                                        userInstance={userInstance}
                                    />
                                )}
                                {formState === "confirmInvitation" && (
                                    <ConfirmInvitationForm
                                        apiService={props.apiService}
                                        loading={loading}
                                        onInvitationConfirmPressed={onInvitationConfirmPressed}
                                        onSignInPressed={() => navigateTo("login")}
                                    />
                                )}
                            </div>
                        </Content>
                    </Spin>
                </Col>
            </Row>
        </Layout>
    );
};

export default WelcomeLayout;
