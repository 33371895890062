import React, {Component} from "react";
import {Button, Divider, notification} from "antd";
import {IProjectContext, RootProjectContext} from "../../Contexts/RootProjectContext";
import Editor from "@monaco-editor/react";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../../Api/APIService";
import CustomSpinner from "../../Components/CustomSpinner";

interface Props {
}

interface State {
    changedEnv?: string
    loading: boolean
}

class EnvsLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        this.ctx = this.context
    }

    async save(classInstance: RetterCloudObject) {
        if (!this.state.changedEnv) return false
        this.setState({
            loading: true
        })
        let envs;
        try {
            envs = JSON.parse(this.state.changedEnv)
        } catch (e) {
            notification.error({
                placement: 'bottomRight',
                message: 'Invalid json'
            })
        }
        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.setEnvironmentVariables,
                body: {
                    environmentVariables: envs
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <>
                            <CustomSpinner spinning={this.state.loading}>
                                <Button onClick={async () => {
                                    await this.save(ctx.instance!)
                                }}>Save</Button>
                                <Divider dashed/>
                                <Editor
                                    language={'json'}
                                    height={'70vh'}
                                    defaultValue={JSON.stringify(ctx.detail?.envVars || {},null,2)}
                                    value={ctx.detail?.envVars ? JSON.stringify(ctx.detail?.envVars || {},null,2): '{}'}
                                    onChange={(v) => {
                                        this.setState({changedEnv: v})
                                    }}
                                />
                            </CustomSpinner>

                        </>
                    )}
                </RootProjectContext.Consumer>

            </>
        );
    }
}

EnvsLayout.contextType = RootProjectContext

export default EnvsLayout
