import React, {Component} from 'react';
import PricePie from "./Charts/PricePie";
import {Badge, Collapse, DatePicker, PageHeader} from "antd";
import {APIService} from "../../Api/APIService";
import {ProjectTotalCount} from "../../Interfaces/BillingAccountProjectCount";
import CustomSpinner from "../../Components/CustomSpinner";
import moment from 'moment';

const {Panel} = Collapse;


export interface Props {
    apiService: APIService
    billingAccountId: string
}

export interface State {
    loading: boolean
    billingAccountProjectCounts?: ProjectTotalCount[]
}

export default class BillingAccountOverview extends Component<Props, State> {
    private readonly apiService: APIService

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            loading: true
        }
        this.getProjectCounts = this.getProjectCounts.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    async getProjectCounts(date: Date) {
        const counts = await this.apiService.getBillingAccountProjectsCounts(this.props.billingAccountId, date)
        this.setState({loading: false, billingAccountProjectCounts: counts})
    }

    async componentDidMount() {
        await this.getProjectCounts(new Date())
    }

    async onChange(date: any, dateString: any) {
        this.setState({loading: true})
        await this.getProjectCounts(date._d)
    }

    render() {
        return (
            <>
                <PageHeader
                    ghost={false}
                    title=""
                    subTitle=""
                    extra={[
                        <DatePicker onChange={this.onChange} defaultValue={moment(new Date(), 'YYYY-MM')}
                                    picker="month"/>
                    ]}
                >
                    <div>
                        <CustomSpinner spinning={this.state.loading}>
                            {!this.state.loading && this.state.billingAccountProjectCounts ? <>
                                <PricePie
                                    currency={'$'}
                                    data={this.state.billingAccountProjectCounts.map(ba => {
                                        return {value: ba.totalCount.USD, type: ba.projectId}
                                    })}/>
                                Details:
                                <Collapse ghost>
                                    {
                                        this.state.billingAccountProjectCounts && this.state.billingAccountProjectCounts.map((ba, index) => {
                                            return <Panel header={<>{ba.projectId} <Badge
                                                className="site-badge-count-109"
                                                count={ba.totalCount.USD + " $"}
                                                style={{backgroundColor: '#096dd9'}}
                                            /></>} key={index}>
                                                <Collapse ghost>
                                                    {Object.keys(ba.detail.services).map((k, index1) => {
                                                        return <Panel header={<>{k} <Badge
                                                            className="site-badge-count-109"
                                                            count={ba.detail.services[k].totalCount.USD + " $"}
                                                            style={{backgroundColor: '#096dd9'}}
                                                        /></>} key={index1}>
                                                            <Collapse ghost>
                                                                {Object.keys(ba.detail.services[k].actions).map((aKey, aindex) => {
                                                                    return <Panel header={<>{aKey} <Badge
                                                                        className="site-badge-count-109"
                                                                        count={ba.detail.services[k].actions[aKey].totalCount.USD + " $"}
                                                                        style={{backgroundColor: '#096dd9'}}
                                                                    /></>} key={aindex}>
                                                                        {Object.keys(ba.detail.services[k].actions[aKey].coreActions).map((caKey, cindex) => {
                                                                            return <>{caKey} <Badge
                                                                                className="site-badge-count-109"
                                                                                count={ba.detail.services[k].actions[aKey].coreActions[caKey].totalCount.USD + " $"}
                                                                                style={{backgroundColor: '#096dd9'}}
                                                                            /></>
                                                                        })}
                                                                    </Panel>
                                                                })}
                                                            </Collapse>
                                                        </Panel>
                                                    })}
                                                </Collapse>
                                            </Panel>
                                        })
                                    }
                                </Collapse>
                            </> : ""}
                        </CustomSpinner>
                    </div>
                </PageHeader>

            </>
        );
    }
}