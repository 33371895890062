import React, {FC, useContext} from "react";
import {
    Button,
    Descriptions,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Select,
    Table
} from "antd";
import {RootProjectContext} from "../../Contexts/RootProjectContext";
import {
    StateStreamTarget,
    StateStreamTargetType,
    TracingAdaptorItem,
    TracingType
} from "../../Actions/Interfaces/IProject";
import {RootProjectClassMethods} from "../../Api/APIService";
import DestinationFirestoreForm from "./DestinationInputTypes/DestinationFirestoreForm";
import DestinationElasticForm from "./DestinationInputTypes/DestinationElasticForm";
import DestinationHttpForm from "./DestinationInputTypes/DestinationHttpForm";


const TracingAdapters: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAdd, setShowAdd] = React.useState(false)
    const [showDetail, setShowDetail] = React.useState(false)
    const [selectedAdapter, setSelectedAdapter] = React.useState<TracingAdaptorItem>()
    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm()

    const adaptors: string[] = []

    for (const tracingKey in TracingType) {
        adaptors.push(tracingKey)
    }

    const addAdapter = async (values: any) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateTracingAdaptors,
                body: {
                    tracingAdapters: (ctx.detail?.projectConfig.tracingAdapters || []).concat(values)
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        form.resetFields()
        setShowAdd(false)
        setLoading(false)
    }

    const deleteAdapter = async (values: TracingAdaptorItem) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateTracingAdaptors,
                body: {
                    targets: (ctx.detail?.projectConfig.stateStreamTargets || []).filter(item =>
                        !(item.type === values.type)
                    )
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        setShowDetail(false)
        setSelectedAdapter(undefined)
        setLoading(false)
    }

    return (
        <>
            <Button loading={loading} onClick={() => {
                setShowAdd(true)
            }}>Add Tracing Adapter</Button>
            <Divider dashed/>
            <Table dataSource={ctx.detail?.projectConfig.tracingAdapters || []}
                   columns={[
                       {
                           title: 'Type',
                           render: (item: TracingAdaptorItem) => {
                               return <a onClick={() => {
                                   setSelectedAdapter(item)
                                   setShowDetail(true)
                               }}>{item.type}</a>
                           }
                       }
                   ]}/>
            <Drawer title="Detail" width={600} placement="right" onClose={() => {
                setShowDetail(false)
            }} visible={showDetail} extra={
                <Popconfirm
                    title="Are you sure to delete this adapter?"
                    onConfirm={async () => {
                        await deleteAdapter(selectedAdapter!)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger loading={loading}>Delete</Button>
                </Popconfirm>}>
                <Descriptions bordered>
                    <Descriptions.Item span={3} label="Type"> {selectedAdapter?.type} </Descriptions.Item>
                </Descriptions>
            </Drawer>

            <Drawer title="Add Tracing Adapter" width={600} placement="right" onClose={() => {
                setShowAdd(false)
            }} visible={showAdd}
                    extra={<Button loading={loading} form={"upsert-tracing-adapter"} htmlType={"submit"}>Add</Button>}>
                <Form
                    form={form}
                    id={"upsert-tracing-adapter"}
                    name="basic"
                    layout={"vertical"}
                    labelCol={{span: 10}}
                    initialValues={{remember: true}}
                    onFinish={addAdapter}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[{required: true, message: 'Please input type!'}]}
                    >
                        <Select>
                            {
                                adaptors.map(t => {
                                    return <Select.Option value={t}>{t}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default TracingAdapters
