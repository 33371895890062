import {Button, Form, Input, Modal, notification} from "antd";
import React, {Component} from "react";

import CustomSpinner from "../Components/CustomSpinner";
import {PlusCircleOutlined} from "@ant-design/icons";

interface Props {
    successCallback: (fileName: string) => void
}

interface State {
    loading: boolean
    visible: boolean
}

export default class CreateClassFileModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            visible: false,
        }
        this.setIsModalVisible = this.setIsModalVisible.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.init = this.init.bind(this)
    }

    async componentDidMount() {
        await this.init()
    }

    async createClassFile(payload: { fileName: string }) {
        this.setState({
            loading: true
        })
        await this.props.successCallback(payload.fileName)
        this.setState({
            loading: false,
            visible: false
        })
    }

    async init() {
        this.setState({
            loading: false
        })
    }

    setIsModalVisible(visible: boolean) {
        this.setState({
            visible
        })
    }

    showModal() {
        this.setIsModalVisible(true);
    };

    async handleOk(values: { fileName: string }) {
        const extension = values.fileName.split('.').pop()
        const supportedExtensions = ['yml', 'ts', 'js','json']
        if (!supportedExtensions.includes(extension!)) {
            notification["error"]({
                placement: 'bottomRight',
                message: 'Unsupported file extension',
                description: `Supported extensions: ` + supportedExtensions.join(', '),
            })
            return
        }
        await this.createClassFile(values)
    };

    handleCancel() {
        this.setIsModalVisible(false);
    };

    render() {
        return (
            <>
                <Button icon={<PlusCircleOutlined/>} type="link" onClick={this.showModal}>
                    Add New File
                </Button>
                <Modal title="Add File"
                       visible={this.state.visible}
                       footer={[
                           <Button type={"link"} onClick={this.handleCancel}>
                               Cancel
                           </Button>,
                           <Button htmlType={'submit'} type={'primary'} form={'create_class_file'}>
                               Create
                           </Button>
                       ]}
                       okText={'Create'}
                       onCancel={this.handleCancel}>
                    <CustomSpinner spinning={this.state.loading}>
                        <Form
                            id={'create_class_file'}
                            labelCol={{span: 5}}
                            onFinish={this.handleOk}
                            layout="horizontal"
                            name="basic"
                            autoComplete="off"
                        >
                            <Form.Item
                                label="File Name"
                                name="fileName"
                                rules={[{required: true, message: 'Please input file name!'}]}
                            >
                                <Input autoFocus/>
                            </Form.Item>
                        </Form>
                    </CustomSpinner>
                </Modal>
            </>
        );
    }
}