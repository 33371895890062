import React, {Component} from "react";
import {Button, notification, Table} from "antd";
import {RootProjectClassEnums, RootProjectClassMethods} from "../../../Api/APIService";
import {LookupKeyItem, LookupKeysResponse} from "../../../Interfaces/CosInterfaces";
import CustomSpinner from "../../../Components/CustomSpinner";
import RBS from "@retter/sdk";


export interface Props {
    projectId: string
    classId: string
    instanceId: string
    rootRbsSdk: RBS
}

export interface State {
    loading: boolean
    lookupKeyListResponse: LookupKeysResponse
}

export class CosLookupKeyList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            lookupKeyListResponse: {lookupKeys: []}
        }
        this.init = this.init.bind(this)
    }

    async componentDidMount() {
        await this.init()
    }

    async init() {
        this.setState({loading: true})
        const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
            classId: RootProjectClassEnums.RetterClass,
            instanceId: this.props.projectId +'_'+this.props.classId,
        })
        const response = await retterClassInstance.call<any>({
            method: RootProjectClassMethods.getLookupKeys,
            body: {
                instanceId: this.props.instanceId
            }
        })
        if(!response || response.status >= 400){
            notification.error({
                placement: 'bottomRight',
                message: response ? response.data : 'error'
            })
            return false
        }
        if (response) {
            this.setState({lookupKeyListResponse: response.data})
        }
        this.setState({loading: false})
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Table
                        pagination={{defaultPageSize: 50}}
                        dataSource={this.state.lookupKeyListResponse.lookupKeys.map(c => {
                        return {key: c.name + '$' + c.value, name: c.name, value: c.value}
                    })} columns={[
                        {
                            title: <>Name <Button type={'link'} onClick={async () => {
                                await this.init()
                            }}>Refresh</Button></>,
                            key: 'name',
                            render: (item: LookupKeyItem) => {
                                return item.name
                            }
                        },
                        {
                            title: 'Value',
                            key: 'value',
                            render: (item: LookupKeyItem) => {
                                return item.value
                            }
                        }
                    ]}/>
                </CustomSpinner>
            </>
        );
    }

}
