import React, {Component} from "react";
import {Button, Form, Modal, notification} from "antd";
import Editor, {Monaco} from "@monaco-editor/react";
import {RootProjectContext} from "../../Contexts/RootProjectContext";
import {RetterCloudObject} from "@retter/sdk";
import {RootProjectClassMethods} from "../../Api/APIService";
import {IProjectDetail} from "../../Actions/Interfaces/IProject";
import {ModelDefinitions} from "../../Interfaces/IProjectSummary";


interface Props {
    modelName: string
    modelDefinitions?: ModelDefinitions
    onSuccess?: ()=>Promise<any>
}

interface State {
    visible: boolean
    modelObject?: string
    loading: boolean
    registerCompletionItemProvider?: any
}

export class EditModelModal extends Component<Props, State> {
    monaco?: Monaco

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            loading: false
        }
        this.updateModel = this.updateModel.bind(this)
        this.handleEditorDidMount = this.handleEditorDidMount.bind(this)
    }

    async updateModel(classInstance: RetterCloudObject) {
        if (!this.state.modelObject) return false
        this.setState({loading: true})
        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.upsertModel,
                body: {
                    modelName: this.props.modelName,
                    modelDefinition: JSON.parse(this.state.modelObject)
                }
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success'
            })
            this.setState({visible: false})
            if(this.props.onSuccess){
                await this.props.onSuccess()
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data
                })
            }
        }
        this.setState({loading: false})
    }

    async handleEditorDidMount(editor: Monaco, monaco: Monaco, projectDetail?: IProjectDetail) {
        this.monaco = monaco

        const modelDefinitions = this.props.modelDefinitions
        this.setState({
            registerCompletionItemProvider: monaco.languages.registerCompletionItemProvider('json', {
                //triggerCharacters: ['$ref":', ' '],
                provideCompletionItems: function (model: any, position: any) {
                    const wordUntilPosition = model.getWordUntilPosition(position);
                    const suggestions = []

                    if (modelDefinitions) {
                        for (const key of Object.keys(modelDefinitions)) {
                            suggestions.push({
                                label: key,
                                kind: monaco.languages.CompletionItemKind.Text,
                                insertText: `"#/\\$defs/${key}"`,
                                insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                range: {
                                    startLineNumber: position.lineNumber,
                                    startColumn: wordUntilPosition.startColumn,
                                    endLineNumber: position.lineNumber,
                                    endColumn: wordUntilPosition.endColumn - 1,
                                },
                            })
                        }
                    }
                    return {
                        suggestions
                    };
                }
            })
        })

    }

    componentWillUnmount() {
        if (this.state.registerCompletionItemProvider) this.state.registerCompletionItemProvider.dispose()
    }

    onCancel = async () => {
        this.setState({visible: false})
        if (this.state.registerCompletionItemProvider) await this.state.registerCompletionItemProvider.dispose()
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        ctx ? <>
                            <Button type="link" onClick={() => {
                                this.setState({visible: true})
                            }}>
                                Edit
                            </Button>
                            <Modal title={'Edit Model - ' + this.props.modelName} visible={this.state.visible}
                                   width={800}
                                   okText={'Save'}
                                   confirmLoading={this.state.loading}
                                   onOk={async () => {
                                       await this.updateModel(ctx.instance!)
                                   }}
                                   onCancel={this.onCancel}>
                                <Form
                                    name={'update_model'}
                                    layout="vertical"

                                    requiredMark={true}
                                >
                                    <Form.Item
                                        label="Model"
                                        name="model"
                                    >
                                        <Editor
                                            onMount={async (editor: any,
                                                            monaco: Monaco,) => {
                                                await this.handleEditorDidMount(editor, monaco, ctx.detail)
                                            }}
                                            height="60vh"
                                            language={"json"}
                                            defaultValue={JSON.stringify((this.props.modelDefinitions || {})[this.props.modelName], null, 2)}
                                            onChange={(value => {
                                                this.setState({modelObject: value})
                                            })}
                                        />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </> : null
                    )}
                </RootProjectContext.Consumer>

            </>
        );
    }
}
