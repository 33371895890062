import React, {Component} from "react";
import {Button, Divider, Modal, Spin, Upload} from "antd";
import {APIService} from "../Api/APIService";
import {InboxOutlined} from '@ant-design/icons';
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {LiveService} from "../Interfaces/Service";

const {Dragger} = Upload;


interface Props {
    isPublic: boolean
    apiService: APIService
    liveService: LiveService
}

interface State {
    loading: boolean
    visible: boolean
    manifest: string
    fileList: RcFile[]
}

export class UpdateServiceModal extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            loading: false,
            visible: false,
            manifest: "",
            fileList: []
        }
        this.updateService = this.updateService.bind(this)
        this.draggerOnChange = this.draggerOnChange.bind(this)
        this.draggerAction = this.draggerAction.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async updateService(manifest: any) {
        if (this.props.liveService.manifest.secretKey !== manifest.secretKey && !window.confirm('Manifest secret key changed! Are you sure?')) {
            return false
        }
        this.setState({loading: true});
        await this.apiService.updateService(this.props.isPublic || false, manifest, manifest.id, this.props.liveService.updateToken)
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async () => {
        await this.updateService(JSON.parse(this.state.manifest))

    };

    onFinishFailed = (errorInfo: any) => {
    };

    draggerOnChange(e: UploadChangeParam) {
    }

    draggerAction(e: RcFile, fList: RcFile[]) {
        this.setState({fileList: [e], manifest: ""})
        e.text().then((d) => {
            this.setState({manifest: d})
        })
        return false
    }


    render() {
        const {visible, loading} = this.state;

        return (
            <>
                <Button type="primary" onClick={this.showModal}>
                    Update
                </Button>
                <Modal
                    visible={visible}
                    title="Update Service"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>
                    ]}
                >
                    <Spin spinning={loading}>
                        <Dragger
                            fileList={this.state.fileList}
                            key={"uploadcreatemanifest"}
                            name='file'
                            multiple={false}
                            beforeUpload={this.draggerAction}
                            onChange={this.draggerOnChange}
                            accept={'.json'}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag manifest file to this area to process</p>
                            <p className="ant-upload-hint">
                                Support for a single file.
                            </p>
                        </Dragger>
                        <Divider/>
                        {this.state.manifest !== "" ? <>
                            <Button key="Upload" type={"primary"} loading={loading} onClick={this.onFinish}>
                                Upload
                            </Button>
                        </> : ""}
                    </Spin>
                </Modal>
            </>
        );
    }
}